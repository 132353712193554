import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
  HttpParams,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  URL: string;
  Upload_URL: string;
  token: string;
  header: any;

  constructor(private http: HttpClient) {
    // this.URL ='https://mhbgzhf6ad.execute-api.ap-south-1.amazonaws.com/production/';
    this.URL = 'https://0nkr7dmeyd.execute-api.ap-south-1.amazonaws.com/production/';
    //this.URL = 'https://mhbgzhf6ad.execute-api.ap-south-1.amazonaws.com/production/';
    //this.Upload_URL = "https://file.io/";
    const headerSettings: { [name: string]: string | string[] } = {};
    this.header = new HttpHeaders(headerSettings);
  }

  HttpEventResponse(event) {
    switch (event.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        const loaded = Math.round(event.loaded / 1024);
        break;
      case HttpEventType.Response:
        return event.body;
    }
  }

  // signup(data){
  //   const request = new HttpRequest('POST', this.URL+'user',data,{ headers: new HttpHeaders({
  //   }), reportProgress: true })//, headers: this.appHeader
  //   return this.http.request(request)
  // }

  signup(data: any) {
    return this.http.post(this.URL + 'register', data);
  }

  login(data: any) {
    return this.http.post(this.URL + 'login', data);
  }

  sendOtp(data: any) {
    return this.http.post(this.URL + 'sendotp', data);
  }
  verifyOtp(data: any) {
    return this.http.post(this.URL + 'verifyotp', data);
  }

  forgotpassword(data: any) {
    return this.http.post(this.URL + 'forgotpassword', data);
  }


  getUserData(data: any) {
    return this.http.post(this.URL + 'getUserData', data);
  }
  getUserImageData(data:any){
    return this.http.post(this.URL + 'getUserImageData', data);
  }

  sendOtpForID(data: any) {
    return this.http.post(this.URL + 'sendOtpForFP', data);
  }
  verifyForID(data: any) {
    return this.http.post(this.URL + 'verifyOtpForFP', data);
  }

  public imageUpload(formData: any) {
    return this.http.post(this.URL + 'imageUpload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  saveimageData(data: any) {
    return this.http.post(this.URL + 'saveImageData', data);
  }

  getUserBappaData(data:any){
    return this.http.post(this.URL + 'getUserBappaData', data);
  }


  public selfieUpload(formData: any) {
    return this.http.post(this.URL + 'selfieUpload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  saveselfieData(data: any) {
    return this.http.post(this.URL + 'saveSelfieData', data);
  }

  getUserSelfieData(data:any){
    return this.http.post(this.URL + 'getUserSelfieData', data);
  }


  public paintingsUpload(formData: any) {
    return this.http.post(this.URL + 'paintingsUpload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  savePaintingsData(data: any) {
    return this.http.post(this.URL + 'savePaintingsData', data);
  }

  getUserPaintingsData(data:any){
    return this.http.post(this.URL + 'getUserPaintingsData', data);
  }


  editProfile(data:any){
    return this.http.post(this.URL + 'editProfile', data);
  }

  getALlUserList() {
    return this.http.get(this.URL + 'getALlUserList');
  }

  getALlImageData() {
    return this.http.get(this.URL + 'getALlImageData');
  }

  getUserImageDataForAdmin(data:any){
    return this.http.post(this.URL + 'getUserImageDataForAdmin', data);
  }

  adminLogin(data: any) {
    return this.http.post(this.URL + 'adminlogin', data);
  }

  changeUserStatus(data: any) {
    return this.http.post(this.URL + 'changeUserStatus', data);
  }

  changeImageStatus(data: any) {
    return this.http.post(this.URL + 'changeImageStatus', data);
  }


  submitBappaForContest(data: any) {
    return this.http.post(this.URL + 'submitBappaForContest', data);
  }

  getAllApprovedBappa(){
    return this.http.get(this.URL + 'getAllApprovedBappa');
  }

  getNextApprovedBappa(data:any){
    return this.http.post(this.URL + 'getNextApprovedBappa', data);
  }

  getSingleBappaData(data:any){
    return this.http.post(this.URL + 'getSingleBappaData', data);
  }


  getTop3Bappa(){
    return this.http.get(this.URL + 'getTop3Bappa');
  }

  searchUserBappa(data:any){
    return this.http.post(this.URL + 'searchUserBappa', data);
  }

  submitSelfieForContest(data: any) {
    return this.http.post(this.URL + 'submitSelfieForContest', data);
  }

  getAllApprovedSelfie(){
    return this.http.get(this.URL + 'getAllApprovedSelfie');
  }

  getNextApprovedSelfie(data:any){
    return this.http.post(this.URL + 'getNextApprovedSelfie', data);
  }

  getSingleSelfieData(data:any){
    return this.http.post(this.URL + 'getSingleSelfieData', data);
  }

  getTop3Selfie(){
    return this.http.get(this.URL + 'getTop3Selfie');
  }

  searchUserSelfie(data:any){
    return this.http.post(this.URL + 'searchUserSelfie', data);
  }

  submitPaintingForContest(data: any) {
    return this.http.post(this.URL + 'submitPaintingForContest', data);
  }

  getAllApprovedPaintings(){
    return this.http.get(this.URL + 'getAllApprovedPaintings');
  }

  getNextApprovedPaintings(data:any){
    return this.http.post(this.URL + 'getNextApprovedPaintings', data);
  }

  getSinglePaintingsData(data:any){
    return this.http.post(this.URL + 'getSinglePaintingsData', data);
  }

  getTop3Paintings(){
    return this.http.get(this.URL + 'getTop3Paintings');
  }

  searchUserPainting(data:any){
    return this.http.post(this.URL + 'searchUserPainting', data);
  }

  getAllApprovedPandals(){
    return this.http.get(this.URL + 'getAllApprovedPandals');
  }

  getNextApprovedPandals(data:any){
    return this.http.post(this.URL + 'getNextApprovedPandals', data);
  }

  getSinglePandalData(data:any){
    return this.http.post(this.URL + 'getSinglePandalData', data);
  }

  getTop3Pandals(){
    return this.http.get(this.URL + 'getTop3Pandals');
  }


  getArtGalleryData(){
    return this.http.get(this.URL + 'getArtGalleryData');
  }


  voteForBappa(data: any) {
    return this.http.post(this.URL + 'voteForBappa', data);
  }

  // voteForBappa(data:any){
  //   const request = new HttpRequest('POST', this.URL+'voteForBappa',data,{ headers: new HttpHeaders({
  //   }), reportProgress: true })//, headers: this.appHeader
  //   return this.http.request(request)
  // }

  voteForSelfie(data: any) {
    return this.http.post(this.URL + 'voteForSelfie', data);
  }

  voteForPaintings(data: any) {
    return this.http.post(this.URL + 'voteForPaintings', data);
  }

  voteForPandal(data: any) {
    return this.http.post(this.URL + 'voteForPandal', data);
  }

  public pandalUpload(formData: any) {
    return this.http.post(this.URL + 'pandalUpload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  savePandalData(data: any) {
    return this.http.post(this.URL + 'savePandalData', data);
  }

  searchPandalByPandal(data: any) {
    return this.http.post(this.URL + 'searchPandalByPandal', data)
  }


  getPandalsBySearchId(data:any){
    return this.http.post(this.URL + 'getPandalsBySearchId', data)
  }


  selfVoteForBappa(data:any){
    return this.http.post(this.URL + 'selfVoteForBappa', data);
  }

  selfVoteForPaintings(data:any){
    return this.http.post(this.URL + 'selfVoteForPaintings', data);
  }

  selfVoteForPandal(data:any){
    return this.http.post(this.URL + 'selfVoteForPandal', data);
  }

  selfVoteForSelfie(data:any){
    return this.http.post(this.URL + 'selfVoteForSelfie', data);
  }

  getLiveCornerData(data:any){
    return this.http.post(this.URL + 'getLiveCornerData', data)
  }

  getFbLiveCornerData(data:any){
    return this.http.post(this.URL + 'getFbLiveCornerData', data)
  }

 
}
