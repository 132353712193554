import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  city:string="";
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToHome(city: string){
    this.city=city;
    localStorage.setItem('city', city)
    this.router.navigateByUrl('/home');
  }

}
