<app-inner-header [title]="title"></app-inner-header>
<main id="main">

  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-end align-items-right">
        <!-- <h2>Registration</h2> -->
        <ol>
          <li><a routerLink="/">Home</a></li>
          <li>{{title}}</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->


  <section class="inner-page parallax">
    <div class="container" style="position: relative;">
      <!-- ======= Login Section ======= -->


      <section id="book-a-table" class="book-a-table">
        <div class="container">
          <div class="row col-lg-12 d-flex justify-content-end">

            <div class="text-center col-lg-4 mt-3 mb-3">
              <a class="btn-dashboard" style="color:#fff !important; text-decoration: none !important;"
                routerLink="/register">User Registration</a>
            </div>

          </div>

          <div class="row d-flex justify-content-center text-center">

            <div class="col-lg-12 col-md-12 col-sm-12">

              <div class="section-title">
                <h2>Pandal <span>Registration</span></h2>
                <p>Register your pandal for contest</p>
              </div>

              <div class="row justify-content-center mt-2">
                <div class="card col-md-8">
                  <div class="col-lg-12" data-form-type="formoid">
                    <div style="padding-bottom: 12px; width: 100%">
                      <form [formGroup]="imgForm" class="php-email-form">
                        <div class="row d-flex justify-content-center" style="margin-top: 5%">
                          <div class="row d-flex justify-content-center mt-2" style="height:auto">
                            <div class="text-center col-md-6 col-lg-6 col-sm-6 rounded corners">

                              <div class="upload-btn-wrapper" style="margin: auto">
                                <button class="uploadbtn">
                                  <div class="text-center col-md-12 rounded corners"
                                    style="width:100%; max-width: 320px; margin: auto" *ngIf="imageUploaded">
                                    <!-- <p style="padding-top: 10px">Cropped Image Preview</p> -->
                                    <img [src]="croppedImage" style="height: 100%; width: 100%; margin: auto" loading="lazy" />
                                  </div>
                                  <div style="width:100%; margin: auto" *ngIf="!imageUploaded">
                                    <small>Upload Pandal </small>
                                    <img src="assets/img/pandal_icon.png"
                                      style="width:100%; max-width: 320px; margin: auto" loading="lazy" />
                                  </div>
                                </button>
                                <input type="file" name="myfile" accept="image/*" (change)="fileChangeEvent($event)"
                                  style="height: 100%" />
                              </div>

                            </div>
                            <div class="upload-btn-wrapper">
                              <div class="uploadbtn text-center col-md-6 col-lg-6 col-sm-6 rounded corners" style="height:100%; max-width: 220px; margin: auto" *ngIf="imageUploaded">

                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                  [aspectRatio]="4 / 4" [resizeToWidth]="512" format="png"
                                  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                  (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                              </div>
                            </div>

                          </div>

                          <div class="row justify-content-center mt-2">
                            <div class="col-lg-6 col-md col-sm-6 form-group" data-for="name">
                              <input id="pandalName" name="pandalName" placeholder="Pandal Name" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.pandalName.errors }"
                                formControlName="pandalName" />
                              <div *ngIf="submitted && f.pandalName.errors" class="invalid-feedback">
                                <div *ngIf="f.pandalName.errors.required">* Pandal Name is required</div>
                              </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12 form-group" data-for="phone">
                              <input id="userPhone" name="userPhone" placeholder="Mobile Number" (keypress)="hideOTP()"
                                class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': submitted && f.userPhone.errors }"
                                formControlName="userPhone" />
                              <div *ngIf="submitted && f.userPhone.errors" class="invalid-feedback">
                                <div *ngIf="f.userPhone.errors.required">
                                  * Mobile Number is required
                                </div>
                                <div *ngIf="f.userPhone.errors.pattern">
                                  * Please, Enter 10 digit Mobile Number
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-12 form-group" style="padding-left: 0px !important">
                              <Button class="btn btn-secondary display-4"
                                [ngClass]="[isSubmit? 'btn btn-secondary display-4': 'btn btn-warning display-4']"
                                (click)="onOtpClick(captchaRef)" [disabled]="!verified">{{ sendButton }}</Button>
                            </div>

                            <div class="col-lg-12 col-12 form-group" *ngIf="sentotp"
                              style="color: #000; text-align: left">
                              {{ messageMobile }}
                            </div>


                            <div class="col-lg-6 col-md-6 col-sm-12 form-group" data-for="otp" *ngIf="sentotp">
                              <input class="form-control" data-form-field="otp" id="otp-form4-2" name="otp"
                                [(ngModel)]="userInfo.otp" placeholder="Enter OTP" type="text" formControlName="otp" />

                              <div *ngIf="verifyotp" class="col-lg-12 col-12 form-group"
                                style="color: #000; text-align: left">
                                {{ messageOtp }}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12 form-group" style="padding: 0 !important"
                              *ngIf="sentotp">
                              <input type="button" class="btn btn-warning display-4" (click)="verifyOtp(userInfo.otp)"
                                [disabled]="!verified" value="Verify" />
                            </div>

                            <div class="col-lg-12 col-md col-12 form-group" data-for="re-captcha">
                              <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible">
                              </re-captcha>
                            </div>



                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="location">
                              <input id="location" name="location" placeholder="Location" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.location.errors }"
                                formControlName="location" />
                              <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                                <div *ngIf="f.location.errors.required">* Location is required</div>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="city">
                              <input id="city" name="city" placeholder="City" class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }" formControlName="city" />
                              <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">* City is required</div>
                              </div>
                            </div>



                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="startDate">

                              <div class="col-9" style="display: inline-block">
                                <input matInput [matDatepicker]="startDate" class="form-control"
                                  placeholder="Start Date" formControlName="startDate" (click)="startDate.open()">

                              </div>
                              <div class="col-3" style="display: inline-block">
                                <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                              </div>


                              <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                                <div *ngIf="f.startDate.errors.required">* Start Date is required</div>
                              </div>

                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="endDate">


                              <div class="col-9" style="display: inline-block">
                                <input matInput [matDatepicker]="endDate" class="form-control" placeholder="End Date"
                                  formControlName="endDate" (click)="endDate.open()">
                              </div>
                              <div class="col-3" style="display: inline-block">
                                <mat-datepicker-toggle [for]="endDate">

                                </mat-datepicker-toggle>

                                <mat-datepicker #endDate></mat-datepicker>
                              </div>

                              <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
                                <div *ngIf="f.endDate.errors.required">* End Date is required</div>
                              </div>

                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="dailyTiming">
                              <input id="dailyTiming" name="dailyTiming" placeholder="Daily Timing" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.dailyTiming.errors }"
                                formControlName="dailyTiming" />
                              <div *ngIf="submitted && f.dailyTiming.errors" class="invalid-feedback">
                                <div *ngIf="f.dailyTiming.errors.required">* Daily Timing is required</div>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="pujaTiming">
                              <input id="pujaTiming" name="pujaTiming" placeholder="Puja Timing" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                formControlName="pujaTiming" />
                              <div *ngIf="submitted && f.pujaTiming.errors" class="invalid-feedback">
                                <div *ngIf="f.pujaTiming.errors.required">* City is required</div>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="dailyTiming">
                              <input id="ytLink" name="ytLink" placeholder="Youtube Link" class="form-control"
                                type="text" formControlName="ytLink" />
                             
                            </div>

                            <div class="col-lg-6 col-md col-sm-12 form-group mt-2" data-for="twitterHandle">
                              <input id="twitterHandle" name="twitterHandle" placeholder="Twitter Handle"
                                class="form-control" type="text" formControlName="twitterHandle" /> 
                            </div>
                          </div>

                          <div class="row justify-content-center mt-2">
                            <div class="col-1 form-group" data-for="checkbox"
                              style="margin-bottom: 0px; text-align: left; max-width: 4% !important;">
                              <input type="checkbox" value="yes" id="npReq" (change)="onChangeState($event, 'yes')" />
                            </div>

                            <div class="col-11 form-group" data-for="checkbox"
                              style="margin-bottom: 0px; text-align: left; color: black">
                              <label for="npReq">Legal declaration stating image is created by the user and no one else
                                can claim copy right on the same,</label>
                            </div>
                          </div>

                          <div class="row d-flex justify-content-center mt-2">
                            <button mat-button><a class="btn-dashboard" style="text-decoration: none !important;"
                                (click)="uploadImage()">Upload</a></button>
                          </div>


                        </div>
                      </form>

                    </div>
                  </div>
                </div>

              </div>



            </div>
          </div>

          <div class="row d-flex justify-content-center">

            <!-- <div class="col-lg-6 col-md-6">
                <div class="member">
                  <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                      style="color:#fff">Upload Selfie</a></button>
                </div>
              </div> -->
            <div class="row mb-3 d-flex justify-content-center text-center">
  
              <!-- <div class="col-lg-4 col-sm-12">
                <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
                  <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                      style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
                  <div class="col-12 col-lg-12 sponsor-grid-left">
                    <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                      <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy" />
                    </a>
  
                  </div>
                </div>
  
              </div> -->
  
  
  
              <!-- <div class="col-lg-8 col-sm-12">
  
                <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
                  <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
                  </p>
  
  
                  <div class="row mb-3 d-flex justify-content-center text-center">
                    <div class="col-4 col-lg-3 sponsor-grid mt-3">
                      <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                        <img src="assets/img/sponsor-logo/asus_logo.png"  loading="lazy"/>
                      </a>
  
                    </div>
  
                    <div class="col-4 col-lg-3 sponsor-grid">
                      <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                        <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy" />
                      </a>
  
                    </div>
  
                    <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                      <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                        <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                      </a>
  
                    </div>
  
                    <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                      <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                        <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                      </a>
  
                    </div>
  
  
  
  
  
                  </div>
  
  
  
  
                </div>
  
  
              </div> -->
  
  
  
  
            </div>
  
  
          </div>

        </div>
      </section><!-- End Login Section -->
    </div>
  </section>

</main><!-- End #main -->

<app-footer></app-footer>