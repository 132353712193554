import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bappa-idol',
  templateUrl: './bappa-idol.component.html',
  styleUrls: ['./bappa-idol.component.css']
})
export class BappaIdolComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  
  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }


}
