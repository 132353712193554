<app-header></app-header>

<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">

        <!-- Slide 1 -->
        <div class="carousel-item active" style="background: #000">
          <div class="carousel-container">
            <div class="carousel-content">

              <img src="assets/img/slide/banner-4.png" style="height:400px" loading="lazy">
              <div class="banner-btn">
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/user-dashboard">{{createBappa}}</a>
                <!-- <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/art-gallery">Art Gallery</a> -->
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                href="https://theartofindia.in/collections/ganeshotsav" target="_blank">Art Gallery</a>
              </div>


            </div>
          </div>
        </div>

       
        <!-- <div class="carousel-item" style="background: #000">
          <div class="carousel-container">
            <div class="carousel-content">
              <img src="assets/img/slide/banner-2.png">
              <div class="banner-btn">
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/user-dashboard">Create Bappa</a>
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/art-gallery">Art Gallery</a>
              </div>

            </div>
          </div>
        </div>

       
        <div class="carousel-item" style="background: #000">

          <div class="carousel-container">
            <div class="carousel-content">
              <img src="assets/img/slide/banner-5.png" style="height:400px">
              <div class="banner-btn">
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/user-dashboard">Create Bappa</a>
                <a class="book-a-table-btn scrollto" style="text-decoration: none !important;"
                  routerLink="/art-gallery">Art Gallery</a>
              </div>
            </div>
          </div>
        </div> -->

      </div>

      <!-- <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a> -->

    </div>
  </div>
</section><!-- End Hero -->
<main id="main">

  <section id="why-us" class="why-us"  style="padding:1% 0 !important">
    <div class="container">
      <!-- <div class="col-lg-12" style="height:5px; background: #fe6717"></div> -->
      <div class="row mb-3 d-flex justify-content-center text-center">
        <!-- <div class="col-sm-12 nft_logo_mob"> <img src="assets/img/timesnft-logo.png" loading="lazy"></div> -->
      </div>

      <div class="row mb-3 d-flex justify-content-center text-center" *ngIf="city !='Mumbai' " >
        <!-- <div class="col-lg-4 col-sm-12">
          <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
            <p class="animate__animated animate__fadeInDown display-7"
              style="color:#fe6717; margin-top:10px;"><span
                style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
            <div class="col-12 col-lg-12 sponsor-grid-left">
              <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy"/>
              </a>
              
            </div>
          </div>

        </div> -->

       

        <!-- <div class="col-lg-8 col-sm-12">
          <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
            <p class="display-7 association"><span
                style="color: rgba(26, 24, 22, 0.85);">In association</span> with</p>
            <div class="row mb-3 d-flex justify-content-center text-center">
              <div class="col-4 col-lg-3 sponsor-grid mt-3">
                <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                  <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy" />
                </a>
              </div>
              <div class="col-4 col-lg-3 sponsor-grid">
                <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                  <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                </a>
              </div>
              <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3" style="width:220px !important">
                <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                  <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                </a>
              </div>
              <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                  <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                </a>
              </div>
            </div>
            



          </div>


        </div> -->




      </div>

      <div class="row d-flex justify-content-center text-center text-area">
        <h2 class="animate__animated animate__fadeInDown display-4" style="color:#fe6717"><span
            style="color: rgba(26, 24, 22, 0.85);">Times</span> Bappa</h2>

        <p>
          Create your own Ganesha avatar & customize using engaging decorations like backgrounds, lights, prasad,
          flowers etc.<br>
          Also, enjoy Live aartis from your favoutite pandals with family & friends.
        </p>


        <!-- <ngx-slick-carousel class="carousel" 
        #slickModal="slick-carousel" 
        [config]="slideConfig" 
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <img src="{{ slide.img }}" alt="" width="100%">
        </div>
      </ngx-slick-carousel> -->



      </div>




    </div>
  </section>




  <!-- ======= Chefs Section ======= -->
  <section class="chefs" id="bappa-idol-desk" style="padding:1% 0 !important">
    <div class="container">
      <div class="section-title">
        <h2>Top Voted<span> Bappa Idol</span></h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let bappa of bappaData; let i = index">
          <div class="member">
            <div class="pic">
              <img class="img-fluid" style="width:100%; max-width:300px" loading="lazy"src='{{environment.image_url + "bappaidol/" + bappa.imagePath}}' />
            </div>
            <div class="member-info">
              <h4>Created By : {{bappa?.userName | titlecase}}</h4>
              <span>Votes : {{bappa?.voteCount}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bappa-idol-mob" class="events" style="background: #202020 !important">
    <div class="container">
      <div class="section-title">
        <h2>Top Voted <span>Bappa Idol</span></h2>
      </div>
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide text-center" *ngFor="let bappa of bappaData; let i = index">
            <div class="col-lg-6">
              <img alt="" class="img-fluid" loading="lazy"
                src='{{environment.image_url + "bappaidol/" + bappa.imagePath}}' />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" style="color: #fff">
              <h3>{{bappa.userName | titlecase}} </h3>
              <div class="price">
                <p><span>Votes: </span>{{bappa.voteCount}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </section> <!-- End Chefs Section -->

  <!-- ======= Events Section ======= -->
  <section id="events" class="events"   style="background: #d8d6d6 !important">
    <div class="container">
      <div class="section-title">
        <h2 style="color:#000">Top Voted <span>Pandals</span></h2>
      </div>
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide text-center" *ngFor="let pandal of pandalData">
            <div class="col-lg-6">
              <img alt="" class="img-fluid" loading="lazy"
                src='{{environment.image_url + "pandalData/" + pandal.imagePath}}' />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content"> 
              <h3>{{pandal.pandalName | titlecase}}</h3>
              <h4>{{pandal.location | titlecase}}, {{pandal.city | titlecase}}</h4>
              <!-- <p><strong style="color:#fe6717">Visitor Timing:</strong> {{pandal.dailyTiming}}</p> -->
              <p><strong style="color:#fe6717">Puja Timing:</strong> {{pandal.pujaTiming}}</p>
              <p>
                <a href="{{pandal.ytLink}}" target="_blank" *ngIf="pandal.ytLink != ''">YouTube | </a>
                <a href="{{pandal.twitterHandle}}" target="_blank" *ngIf="pandal.twitterHandle != ''">Twitter</a>
              </p>
              <div class="price">
                <p><span>Votes: </span>{{pandal.voteCount}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </section><!-- End Events Section -->


  <!-- ======= About Section ======= -->

  <section class="chefs" id="bappa-selfie-desk" *ngIf="showBappa == true" >
    <div class="container">
      <div class="section-title">
        <h2>Top Voted <span>Bappa Selfies</span></h2>
      </div>

      <div class="row">
        <div class="row col-lg-12" style="margin:0 auto; text-align:center">
          <div class="form-group col-lg-4" *ngFor="let selfie of selfieData; let i = index">
            <div class="card shadow-sm mb-5 bg-light rounded"
              style="width:100%; max-width:400px; overflow: hidden; box-shadow: 50px 100px 50px #888888;">
              <div class="view overlay">
                <img class="zoom pre-zoom" loading="lazy"
                  src='{{environment.image_url + "selfie/" + selfie.imagePath}}'>
              </div>
              <div class="card-body">
                <div style="margin-top: 24px;">
                  <p>By: {{selfie.userName | titlecase}}</p>
                  <span>Votes: {{selfie.voteCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bappa-selfie-mob" class="events" style="background: #202020 !important">
    <div class="container">
      <div class="section-title">
        <h2>Top Voted <span>Selfies</span></h2>
      </div>
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide text-center" *ngFor="let selfie of selfieData; let i = index">
            <div class="col-lg-6">
              <img alt="" class="img-fluid" loading="lazy"
                src='{{environment.image_url + "selfie/" + selfie.imagePath}}' />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content" style="color: #fff">
              <h3>{{selfie.userName | titlecase}} </h3>
              <div class="price">
                <p><span>Votes: </span>{{selfie.voteCount}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>
    </div>
  </section>

  <!-- End About Section -->

  <!-- ======= Gallery Section ======= -->
  <section class="gallery" id="bappa-paintings-desk"  *ngIf="showBappa == true" style="padding:1% 0 !important; background: #d8d6d6 !important">
    <div class="container-fluid">
      <div class="section-title mb-3">
        <h2>Top Voted <span>Reader Paintings <span>&#38;</span> Drawings</span></h2>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-4 col-md-4" *ngFor="let painting of paintingData; let i = index">
          <div class="gallery-item">

            <a class="gallery-lightbox" lightbox href="{{paintingsImgUrl+painting.imagePath}}">
              <img alt="" class="img-fluid"
                src='{{environment.image_url + "paintings/" + painting.imagePath}}' loading="lazy"/>
            </a>
          </div>
          <div class="member-info">
            <h5> By: {{painting.userName | titlecase}}</h5>
            <span>Votes: {{painting.voteCount}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bappa-paintings-mob" class="events" style="background: #d8d6d6 !important">
    <div class="container">
      <div class="section-title">
        <h2 style="color:#000">Top Voted <span>Reader Paintings <span>&#38;</span> Drawings</span></h2>
      </div>
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide text-center" *ngFor="let painting of paintingData; let i = index">
            <div class="col-lg-6">
              <img alt="" class="img-fluid"
                src='{{environment.image_url + "paintings/" + painting.imagePath}}'loading="lazy" />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content">
              <h5> By: {{painting.userName | titlecase}}</h5>
              
              
              <div class="price">
                <p><span>Votes: </span>{{painting.voteCount}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </swiper>

    </div>
  </section>
  <!-- End Gallery Section -->


</main>
<app-footer></app-footer>