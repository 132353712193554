<div mat-dialog-content class="card shadow-sm bg-white rounded" style="width:100%; height:100%;">
    <button type="button" (click)="close()" class="close dialog-close-btn" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    
    
    <div class="card-header card-header-danger" style="background: #6b6867; color: #fff">
        <h4 class="card-title">PRIVACY POLICY</h4>
        
    </div>
     <div class="card-body">
      
        <p>Bennett, Coleman & Co. Ltd ('we', 'us', 'our', 'BCCL ','Company') values the trust placed in us by data subject ('you', 'your', 'user', 'subscriber') and therefore, we follow the highest standards of privacy guidelines to protect the information shared by you with us.
        This Privacy Policy describes the usage of information provided or collected, disclosed or transferred of your information by Bennett Coleman & Co. Ltd (hereinafter referred to as 'BCCL') and/ or its subsidiary (ies) and / or affiliate(s) (collectively referred to as the ' Company'), which operates various websites/ mobile applications & other services including but not limited to delivery of information and content via any mobile or internet connected device or otherwise (collectively the 'Services'). We follow this privacy policy in accordance with applicable law in the places where we operate. In some cases, we may provide additional data privacy notices specific to certain services or regions
        Please keep in mind that when you provide information to us on a third-party site or platform (for example, via our website or applications like social media login (for e.g. facebook, google+ etc.) the information we collect is through those third-party sites linked with our websites and applications and is covered by this privacy policy, and the information the third-party site or platform collects is subject to the third-party site or platform's privacy practices. Privacy choices you have made on the third-party site or platform will not apply to our use of the information we have collected directly through our websites or applications. Please also keep in observance that our websites and applications may contain links to other sites not owned or controlled by us and we are not responsible for the privacy practices of those sites. We encourage you to be aware when you leave our websites or applications to read the privacy policies of other sites they may collect your personal information.
        All capitalized terms that have not been specifically defined herein shall have the same meaning as provided under the Terms of Use.
        This Privacy Policy should be read in conjunction and together with the Terms of Use applicable to the BCCL services you are using.</p>
        
        <h6>User Consent</h6>
        
        <p>By using BCCL website and its services (for example, when you visit our website and read news or browse through various other portions of our websites or register via social media or via the registration form), you agree to provide consent to our collection, use and sharing of your personal information as described in this policy. In some cases, particularly if you reside in a country governed by a data protection regulation, we may ask you to provide explicit consent to access our services before proceeding for further operations.
          Collection and Use of Personal Information</p>

        <p>'Personal Information' or 'PII' is defined as any information that identifies (whether directly or indirectly) to a particular individual or natural person, such as the individual's name, postal address, email address, mobile number and any other identifier indicating to that particular person. When anonymous information is directly or indirectly associated with personal information, the resulting information also is treated as personal information. The Company respects the privacy of the users of the Services they use and is committed to reasonably protect it in all respects. The information about the user as collected by the Company are:  </p>
        
        <ul>
          <li>(a) Information supplied by users </li>
          <li>(b) Information automatically tracked while navigation  </li>
          <li>(c) Inferred information through usage and log data </li> 
          <li>(d) Information collected from any other sources (like third party providers or social media platforms)</li>
        </ul>
        
        <h6>A. Information you provide to us</h6>
        Information collected at the time of registration
        When you register on the website or applications for the service, we ask that you provide basic personal information from you to continue to interact with you and to continue providing you the Services. Information that we collect incudes -
        
        a) name
        b) gender
        c) age
        d) address
        e) pin code
        f) city
        g) country
        h) contact number
        i) email address
        Information Collected when you use our Mobile Application
        When you download our mobile application we collect the below information from you-
        
        1. Location/IP address for geo-locating
        2. Device ID
        3. Device Type
        4. SMS for OTP
        5. Gallery permissions for storing images
        Subscription or paid service data:
        When you chose any subscription or paid service, we or our payment gateway provider may collect your personal information such as address or billing information, including your credit/debit card number and expiration date etc. The subscriptions or paid Services may be on auto renewal mode unless cancelled. If at any point you do not wish to auto-renew your subscription, you may cancel your subscription before the end of the subscription term or contact us on our support e-mail.
        Information you provide to us voluntarily:
        We may collect additional information at other times, including but not limited to, when you provide feedback to BCCL or you change your content or email preferences, respond to a survey, or communicate with us.
        B. Information automatically collected while browsing/navigating through the website Cookies
        To improve the responsiveness of the 'website' for our users, we may use 'cookies', or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the identified computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply. A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control. We receive and store certain types of information whenever you interact with us via the website. 
        C. Inferred Information through usage and log data
        We may track certain information about you based upon your behaviour on using BCCL services. We use this information for conducting internal research on our users' demographics, devices, interests, and behaviour to better understand, protect and serve our users. We may also collect Your Information to track user behaviour and preferences for internal analytics and research. We may also use your information:
        
        a) To evaluate your interest for various genres of content, services, offers;
        b) To perform analytics and conduct customer research, to determine your interest, for identifying content that generate sales and to analyse traffic patterns. If you choose to post messages on social media accounts, message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law. The above information may also be captured by keeping cookies on our websites by our affiliates for the purposes mentioned above If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on our services, we may collect and store such information.
        D. Information we collect from third parties
        Information obtained from other sources
        We may receive information about you from other sources, add it to our account information and treat it in accordance with this policy. If you provide information to the platform provider or other partner, whom we provide services, your account information and order information may be passed on to us. We may obtain updated contact information from third parties in order to correct our records and fulfil the Services or to communicate with you.
        Demographic and purchase information
        We may reference other sources of demographic and other information in order to provide you with more targeted communications and promotions. We use Google Analytics, among others, to track the user behaviour on our website. Google Analytics specifically has been able to support display advertising towards helping us gain understanding of our users' Demographics and Interests. The reports are anonymous and cannot be associated with any individual personally identifiable information that you may have shared with us. You can opt-out of Google Analytics for Display Advertising and customize Google Display Network ads using the Ad settings options provided by Google or by giving a consent at to serve you advertisements when you visit our website or applications
        Links to third party websites
        The Application may include links to other websites or applications. Such websites or applications are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the application, website you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these sites via a link from the application's/website homepage, you should contact the application/website owners directly for more information. We do not provide any personally identifiable information to third party websites / advertisers / ad-servers without your explicit consent.
        Purposes and Lawfulness of processing
        We will only collect and process personal data about you where we have lawful basis. Lawful basis on which we would process your personal information includes obtaining explicit consent from you or processing for 'legitimate interests' where processing is necessary by us to provide you with services (For e.g. processing your information by our group companies/affiliates).  We use information to provide, analyse, administer, enhance and personalize our service and marketing efforts, to process your registration, to provide you services, and to communicate with you related to below mentioned points. For example, we use information to:
        
        a) To help advertisers understand our audience and confirm the value of advertising on our websites or applications (however it is usually in the form of aggregated statistics on traffic to various pages within our site) and to provide you relevant ads
        b) Communicate with you concerning our service (for example by email, push notifications), so that we can send you news about BCCL, details about new features and content available on the BCCL Services, promotional announcements, and surveys, and to assist you with operational requests such as password reset requests.
        c) Notify you about changes in terms of service or privacy policy
        d) Allow you to participate in interactive features offered through our Services
        e) Sharing with our customer support team to resolve your queries/concerns related to your rights or services provided to you
        Information use by the company
        The Information supplied by you enables us to improve our services and provide you the most user-friendly experience(s). In some cases for e.g. provision of certain service(s) or utility (ies), we may require your contact information as well. All required information is service dependent and the Company may use the above said user Information to, maintain, protect, and improve the Services (including advertising) and for developing new services.
        Any personally identifiable information provided by you will not be considered as sensitive if it is freely available and / or accessible in the public domain like any comments, messages, blogs, scribbles available on social platforms like Facebook, twitter etc. Any posted/uploaded/conveyed/communication by users on the public sections of the 'application/websites' becomes published content and is not considered personally identifiable information subject to this Privacy Policy.
        In case you choose to decline to submit personally identifiable information on the application/websites, we may not be able to provide certain services on the application/websites to you. We will make reasonable efforts to notify you of the same at the time of opening your account. In any case, we will not be liable and or responsible for the denial of certain services to you for lack of you providing the necessary personal information.
        When you register with our Services, we may contact you from time to time about updating your personal information to provide you features that we believe may benefit / interest you.
        Information sharing to third parties
        The Company shares your information with any third parties without obtaining the prior consent of User in the following limited circumstances:
        
        a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms or for complying with the applicable laws and regulations.
        b) The Company proposes to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
        c) The Company may present information to our advertisers - to help them understand our audience and confirm the value of advertising on our websites or Applications - however it is usually in the form of aggregated statistics on traffic to various pages within our site.
        d) The Company may share your information regarding your activities on websites or applications with third party social websites to populate your social wall that is visible to other people however you will have an option to set your privacy settings, where you can decide what you would like to share or not to share with others.
        Your Controls and Choices
        For any of the below request you may send us an e-mail to our support team
        A. Accessing and Rectifying your personal information
        When you use the Services (website/application or any of its sub sites), we make good faith efforts to provide you, as and when requested by you, with access to your personal information and shall further ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible. We have provided the user an option to correct/update his personal information on their own by clicking on 'My Account' settings post login and then they may update their information
        B. Data Portability
        You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format; wherever feasible. You may send your request by sending an e-mail to our support team
        C. Data Retention and Erasure
        We retain your personal information as long as necessary for us to provide services to you or you ask us to not retain your data. If you no longer want us to use your information then you can go to your 'My Account' settings and delete your account and close your BCCL account. Please note that if you delete your account
        
        a) We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend a BCCL account for fraud or safety reasons, we may retain certain information from that account to prevent that user from opening a new account in the future.
        b)We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, we may keep some of your information for tax, legal reporting and auditing obligations.
        c) Information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible on the BCCL, even after your account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers
        d) Because of the way we maintain certain services, after you delete your information, residual copies may take a period of time before they are deleted from our active servers and may remain in our backup systems.
        D. Withdrawing Consent and Restriction of Processing
        For withdrawing your consent at any time during the tenure of your services with us, you may choose to do so by sending us an e-mail. We shall review your request and may ask you to verify your identity. Post verification we will withdraw the consent for which request was made by you and stop any further processing of your personal information. You may also withdraw your consent for cookies and advertisement by going to 'Settings' and click on consent and withdraw the consent.
        E. Complaints
        You can write to us in case of any grievance or compliant at the support e-mail address. If you have any complaints regarding processing of your personal information you may contact our data protection officer or our grievance officer at the below mentioned e-mail address. You have the right to complaint about the data processing activities carried out by BCCL before the competent data protection authorities.
        F. Objection to Processing
        Where your personal information is processed for direct marketing purposes or any contests or promotions or other services, you may, at any time ask us to cease processing your data for these direct marketing purposes by sending an e-mail to us
        Please be aware that if you do not allow us to collect personal information from you, we may not be able to deliver certain experiences, and services to you, and some of our services may not be able to take account of your interests and preferences. If collection of personal information is mandatory, we will make that clear at the point of collection so that you can make an informed decision whether to participate. If you have questions about the specific personal information about you that we process or retain, and your rights regarding that personal information, please contact us at the below mentioned e-mail address.
        Children's Privacy
        BCCL is not intentionally designed for or directed at persons less than 16 years of age. BCCL does not knowingly permit any person who is under 16 years of age to register with the services or to provide any other personally identifying information. If BCCL becomes aware that any personally identifiable information of persons less than 16 years of age has been collected on BCCL Services without verified parental consent, then BCCL will take the appropriate steps to delete any such information and notify the parent.
        However, we consider it the responsibility of parents to monitor their children's use of our services.
        Nevertheless, it is our policy not to collect and process any personal information from children below 16 years of age or offer to send any promotional materials to persons in that category. BCCL does not seek or intend to seek or receive any personal information from children. Should a parent or guardian have reasons to believe that a minor has provided BCCL with personal information without their prior consent, please contact our customer support team to ensure that the personal information is removed from the BCCL services
        Data transfer, storage & processing globally
        We operate globally and may transfer your personal information to individual companies of the BCCL affiliated companies or third parties in locations around the world for the purposes described in this privacy policy. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information. Additionally, when using or disclosing personal information transferred from the European Union, we use standard contractual clauses approved by the European Commission, adopt other means under European Union law for ensuring adequate safeguards.
        Security and compliance with laws
        We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. All information gathered on the Site is securely stored within the BCCL controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. If you know or have reason to believe that your BCCL account credentials have been lost, stolen, altered, or otherwise compromised or in case of any actual or suspected unauthorized use of your account, please contact us by contacting our customer support team.
        Changes in the Policy
        The internet is an ever evolving medium. We may alter our privacy policy from time to time to incorporate necessary changes in technology, applicable law or any other variant. In any case, we reserve the right to change (at any point of time) the terms of this Privacy Policy or the Terms of Use. Any changes we make will be effective immediately on notice, which we may give by posting the new policy on the 'Site'. Your use of the Site or services offered through the Site after such notice will be deemed acceptance of such changes. We may also make reasonable efforts to inform you via electronic mail. In any case, you are advised to review this Privacy Policy periodically on the 'Site') to ensure that you are aware of the latest version.
        Contact information
        Support
        If you require any information or clarification regarding the use of your personal information or this privacy policy or grievances with respect to use of your personal information, Please contact us. 
        Data Controller Office
        The data controller for our services is Bennett Coleman & Co. Ltd. with the business address of Times of India Office, 3rd Floor, Dr. D.N.Road,  Fort, Mumbai  Maharashtra - 400001  The data protection officer (DPO) appointed by BCCL is Mr. Rajeev Batra who can be contacted  at rajeev.batra@timesgroup.com
        Redressal Mechanism
        Any complaints, abuse or concerns with regards to the processing of information provided by you or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to rajeev.batra@timesgroup.com
        
        We request you to please provide the following information in your complaint:-
        
        a) Identification of the information provided by you.
        b) Clear statement as to whether the information is personal information or sensitive personal information.
        c) Your address, telephone number or e-mail address.
        d) A statement that you have a good-faith belief that the information has been processed incorrectly or disclosed without authorization, as the case may be;
        e) A statement that you have a good-faith belief that the information has been processed incorrectly or disclosed without authorization, as the case may be.
        f) You may also contact us in case you have any questions / suggestions about the Privacy Policy using the contact information mentioned above.
        BCCL shall not be responsible for any communication, if addressed, to any non-designated person in this regard.
        Cookie Policy
        BCCL Group ('we', 'us', 'BCCL') understands that your privacy is important to data subject ('you', 'your', 'user', 'subscriber') and we are committed for being transparent about the technologies we use. This Cookie Policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit BCCL website or application that posts a link to this Policy (collectively, 'the Sites'). This Cookie Policy should be read together with our Privacy Policy and Terms of Use.
        What are cookies and Other Tracking Technologies?
        A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another. The other tracking technologies work similarly to cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how you use our websites. This allows our websites to recognize your device from those of other users of the websites. The information provided below about cookies also applies to these other tracking technologies.
        How do our sites use cookies and other tracking technologies?
        We use cookies to collect and store information when you access our website and use other BCCL services. We use cookies for various purposes such as:
        
        a) To provide you BCCL Services
        b) To provide non-personalized or personalized advertisements
        c) To identify your location
        d) To identify your browser and device
        e) For analytics and research,
          i. Measure and analyze the audience for each page in order to subsequently improve the ergonomics, browsing, or visibility of content;
          ii. Measure the reliability of Website by analyzing the number of visits to its pages in real time, and offer varied ads that are adapted to users' areas of interest; 
        iii. Cookies may also be saved by social media tools if you use these functionalities (e.g. Facebook, Twitter).
        Type of Cookies
        'First party Cookies' these are the cookies that belong to us and which we place on your device or are those set by a website that is being visited by the user at the time (e.g., cookies placed by BCCL Group)
        'Third-party cookies ' are cookies that another party places on your device through our site (For e.g. advertising agencies may place their cookies on our website which will collect tracking information and serve you with advertisements).
        We use the following types of cookies:
        Persistent Cookies. We use persistent cookies to improve your experience of using the sites. This includes recording your acceptance of our Cookie Policy to remove the cookie message which first appears when you visit the site.
        Session Cookies.Session Cookies are temporary and deleted from your machine when your web browser closes. We use session Cookies to help us track internet usage as described above. You may refuse to accept browser Cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Sites. Unless you have adjusted your browser setting so that it will refuse Cookies, our system will check if Cookies can be captured when you direct your browser to our Sites. The data collected by the websites and/or through cookies that may be placed on your computer will not be kept for longer than is necessary to fulfil the purposes mentioned above. In any event, such information will be kept in our database until we get explicit consent from you to remove all the stored cookies.
        We categorize cookies as follows:
        Strictly Necessary/Technical
        These Cookies are necessary to allow us to operate our Sites so you may access them as you have requested. These Cookies, for example, let us recognize that you have created an account and have logged in/out to access Site content. They also include Cookies that enable us to remember your previous actions within the same browsing session and secure our Sites.
        Analytical/Performance
        These Cookies are used by us or third-party service providers to analyse how the Sites are used and how they are performing. For example, these Cookies track what content are most frequently visited, watch history and from what locations our visitors come from. If you subscribe to a newsletter or otherwise register with the Sites, these Cookies may be correlated to you. These Cookies include, for example, Google Analytics cookies, Comscore cookies.
        Functionality
        These Cookies let us operate the Sites in accordance with the choices you make. These Cookies permit us to 'remember' you in-between visits. For instance, we will recognize your user name and remember how you customized the Sites and services, for example by adjusting text size, fonts, languages and other parts of web pages that are alterable, and provide you with the same customizations during future visits
        Targeting or Advertising Cookies
        These cookies are used to deliver content that is more relevant to you and your interests. They are also used to deliver targeted advertising or limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaigns on BCCL Group and other websites. They remember that you have visited one of our websites and this information is shared with other parties, including advertisers and our agencies. These cookies may also be linked to site functionality provided by third-parties such as Google DFP and Affinity.
        Do these cookies collect personal data/identify me?
        Most types of these cookies track consumers via their Device ID or IP address therefore they may collect personal data.
        Based on the type of cookies used by the relevant third-party, the information these cookies collect may include personal data but they would not be able to directly identify you as an individual or have any of your personal information such as Name, E-mail id or mobile number
        Managing advertising cookies
        Please note that if you want to opt out from receiving targeted advertising, this does not mean that you will receive less advertising when you use our Website. This just means that the advertising you see will not be as customised to you.
        However if you still want to switch off third-party advertising cookies, you can turn do this by visiting the Internet Advertising Bureau's consumer advice site, Youronlinechoices.com This will give you a list of all cookies that are currently set on your device and how to opt out of each of them.
        For more information about and to opt out of interest based ads from many ad networks, go to:
        European Union: www.youronlinechoices.eu
        How do I refuse or withdraw my consent to the use of Cookies?
        How do I refuse or withdraw my consent to the use of Cookies? If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser 'help' / 'tool' or 'edit' section for cookie settings w.r.t your browser that may be Google Chrome, Safari, Mozilla Firefox etc. Please note that if your browser setting is already setup to block all Cookies (including strictly necessary Cookies) you may not be able to access or use all or parts or functionalities of our Sites.
        If you want to remove previously-stored Cookies, you can manually delete the Cookies at any time. However, this will not prevent the Sites from placing further Cookies on your device unless and until you adjust your Internet browser setting as described above.  
        For more information on the development of user-profiles and the use of targeting/advertising Cookies, please see www.youronlinechoices.eu if you are located in Europe or www.aboutads.info/choices if in the United States.
        Choices available through your browser
        All browsers offer configuration options to:
        
        1. Inform you of the cookies saved on your computer.
        2. Allow you to accept or refuse them, on either a case-by-case basis or outright.
        The configuration of each browser is different. We suggest you consult the help menu on your browser in order to configure your browser as you wish.
        More Information
        More detail on how businesses use cookies is available at www.allaboutcookies.org
        If you have any queries regarding this Cookie Policy please contact our Data Protection Officer Mr. Rajeev Batra at rajeev.batra@timesgroup.com
      
    </div> 
    
    
    </div>




