import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  title:string ='Dashboard'
  public userId;
  public token;

  userName: string = ''
  userEmail: string = ''
  userPhone: string = ''
  imagePath:string =''

  getData = {};
  respData: any = {};
  userData: any = {};

  imageData:any={}


  userImageDataArray: any ={}
  userImageData:any={}
  environment=environment;

  created:boolean=false;
  canvasData:any;
  city:string="";
  bappaCraeted:string;
  constructor(private router:Router, private api:ApiService) { }

  ngOnInit(): void {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    this.city= localStorage.getItem('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.router.navigateByUrl('/login');
    }

    this.getUserData();
    
  }

  getUserData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          this.userName = this.userData.name;
          this.userEmail = this.userData.email;
          this.userPhone = this.userData.phone;
          //this.imagePath = this.userData.imagePath;

          localStorage.setItem('userName', this.userData.name);

         // this.profileImageURL = this.profileImageURL + this.imagePath;

          if (this.userData.createBappa == 'yes') {
            this.getUserImageData()
            this.created = true;
            this.bappaCraeted = "yes"

            localStorage.setItem("bappaCreated", this.bappaCraeted);
            
          } else {
            this.created = false;
            this.bappaCraeted = "no"
            localStorage.setItem("bappaCreated", this.bappaCraeted);
          }


          console.log('resp data', this.respData.data);
          console.log('user data', this.userData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }

  getUserImageData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserImageData(this.getData).subscribe(
        (resp) => {
          this.userImageDataArray = resp;
          this.userImageData = this.userImageDataArray.data;

          console.log('resp data', this.userImageDataArray.data);
          console.log('image data', this.userImageData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }


  submitBappaForContest(data: any){
    this.imagePath = data;
      if (this.imagePath !== null) {
        this.imageData['userName'] = this.userName;
        this.imageData['userId'] = this.userId;
        this.imageData['token'] = this.token;
        this.imageData['imagePath'] = this.imagePath;
      
        
        this.api.submitBappaForContest(this.imageData).subscribe(
          (resp) => {
            this.respData = resp;
            if (this.respData.success == true) {
              alert('Your bappa idol for contest has been submitted successfully!');
            } else {
              alert(this.respData.message);
              console.log('error' + this.respData.message);
            }
          },
          (err) => {
           // this.startLoader = false;
            console.log(err);
           
          }
        );
      } else {
        //this.startLoader = false;
        alert('Please create bappa');
       
      }
    
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('bappaCreated');
    
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }

  

}
