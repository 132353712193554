import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pandal',
  templateUrl: './pandal.component.html',
  styleUrls: ['./pandal.component.css']
})
export class PandalComponent implements OnInit {

  title:string ='Pandal Contest'
  public userId;
  public token;

  imgForm: FormGroup;

  userName: string = ''
  userEmail: string = ''
  userPhone: string = ''
  

  getData = {};
  respData: any = {};
  userData: any = {};

  userImageDataArray: any = [];
  userImageData: any = {};
  userImageDataExists: boolean = false;

  imageData:any = {};

 
  submitted = false;
  environment=environment;
  selectedCollection:String='';
  filteredCategory;

  isDoorstepNo: boolean = false;
  npReq: String = '';

  created: boolean= false

  userVoteDataArray: any = [];
  userVoteData: any = {};
  userVoteDataExists: boolean = false;
  pandalId: any = "";
  pandalName: any = "";
  pandalImagePath: any = environment.image_url +"pandalData/";

  constructor(private router:Router, private api:ApiService) { }

  ngOnInit(): void {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });


    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.router.navigateByUrl('/');
    }

    this.getUserData();  
  }



  getUserData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          this.userName = this.userData.name;
          this.userEmail = this.userData.email;
          this.userPhone = this.userData.phone;
          //this.imagePath = this.userData.imagePath;
          this.getUserVoteData();
         // this.profileImageURL = this.profileImageURL + this.imagePath;

         if (this.userData.paintingsUpload == 'yes') {
          
          this.created = true;
          
        } else {
          this.created = false;
        }




          console.log('resp data', this.respData.data);
          console.log('user data', this.userData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }


  

  getUserVoteData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.selfVoteForPandal(this.getData).subscribe(
        (resp) => {
          this.userVoteDataArray = resp;
          this.userVoteData = this.userVoteDataArray.data;

          if(this.userVoteData.success == true){
            this.userVoteDataExists = true;
            console.log('resp data', this.userVoteDataArray.data);
            console.log('vote data', this.userVoteData);

            this.pandalImagePath = this.pandalImagePath+this.userVoteData.imagePath;
            this.pandalName = this.userVoteData.pandalName;

            this.userVoteDataExists = true;

            console.log('image', this.pandalImagePath);
            console.log('pandalName', this.pandalName);

          }else{
            this.userVoteDataExists = false;
          }

          
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }


  
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('bappaCreated');
    
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }

}
