import { Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-bappa-flower',
  templateUrl: './bappa-flower.component.html',
  styleUrls: ['./bappa-flower.component.css']
})
export class BappaFlowerComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
  }

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }
}
