import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArtGalleryComponent } from '../art-gallery/art-gallery.component';
import { BappaContestComponent } from '../bappa-contest/bappa-contest.component';
import { BappaComponent } from './bappa/bappa.component';
import { CreateBappaComponent } from './create-bappa/create-bappa.component';
import { EditBappaComponent } from './edit-bappa/edit-bappa.component';
import { PaintingsComponent } from './paintings/paintings.component';
import { PandalComponent } from './pandal/pandal.component';
import { SelfieComponent } from './selfie/selfie.component';
import { UserDashboardComponent } from './user-dashboard.component';

const routes: Routes = [
  { path: '', component: UserDashboardComponent },
  { path: 'user-dashboard', component: UserDashboardComponent },
  { path:'create-bappa', component: CreateBappaComponent},
  { path:'edit-bappa', component: EditBappaComponent},
  { path:'bappa-contest', component: BappaContestComponent},
  { path: 'art-gallery', component: ArtGalleryComponent},
  { path:'bappa', component: BappaComponent},
  { path:'selfie', component: SelfieComponent},
  { path:'pandal', component: PandalComponent},
  { path:'paintings', component: PaintingsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserDashboardRoutingModule { }
