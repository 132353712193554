import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-pandal-registration',
  templateUrl: './pandal-registration.component.html',
  styleUrls: ['./pandal-registration.component.css']
})
export class PandalRegistrationComponent implements OnInit {
  title: string = "Pandal Registration"
  public userId;
  public token;

  imgForm: FormGroup;

  userName: string = ''
  userEmail: string = ''
  userPhone: string = ''
  

  getData = {};
  respData: any = {};
  userData: any = {};

  userImageDataArray: any = [];
  userImageData: any = {};
  userImageDataExists: boolean = false;

  imageData:any = {};

  imgStatus:boolean = false;
  clicked:boolean = false;
  imagePath:string ="";
  imageUploaded:boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  submitted = false;

  selectedCollection:String='';
  filteredCategory;

  isDoorstepNo: boolean = false;
  npReq: String = '';

  created: boolean= false

  sentotp: boolean = false;
  verifyotp: boolean = false;
  verified: boolean = true;

  v: boolean = false;

  messageOtp: String = '';
  messageMobile: String = '';
  sendButton: String = 'Send OTP';
  isSubmit: boolean = false;

  recaptchaStr = '';
 

  mobile: any = '';
  otpdata: any = {};
  sendMobileNo: any = {};
  

  userInfo = { mobileNumber: '', mobileNumber1: '', otp: '' };

  constructor(private router:Router, private api:ApiService, private fb:FormBuilder, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
   }

  ngOnInit(): void {
    this.imgForm = this.fb.group({
      userPhone: [
        '',
        [Validators.required, Validators.pattern('^((\\-?))?[0-9]{10}$')],
      ],
      otp: ['', [Validators.required]],
      pandalName: ['', [Validators.required]],
      location: ['', [Validators.required]],
      city: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      dailyTiming: ['', [Validators.required]],
      pujaTiming: ['', [Validators.required]],
      ytLink: '',
      twitterHandle: ''
    });
    
  }

  get f() {
    return this.imgForm.controls;
  }

  

  uploadFile(file: any) {
    var pandalId = this.mobile
    console.log('name -- ', pandalId);
    const formData = new FormData();
    formData.append('pandalId', pandalId);
    formData.append('file', file, 'jpg');
    file.inProgress = true;
    console.log('form data', formData);
    this.api.pandalUpload(formData).pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          //this.startLoader = false;
          this.clicked = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          console.log(event.body.data);
          this.imagePath = event.body.imagePath;

          console.log(this.imagePath);
          //alert('Image successfully uploaded');

          this.savePandalData();
        }
      });
  }

  fileChangeEvent(event: any): void {
    console.log('event - ', event)
    this.imageChangedEvent = event;
    this.imageUploaded = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    // console.log(this.croppedImage)
  }
  imageLoaded() {
    // console.log('value', this.croppedImage)
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  uploadImage() {
    if (this.croppedImage == '') {
      alert('Please upload Pandal Image');
    } else {
      this.clicked = true;
     // this.startLoader = true;
      const file = this.DataURIToBlob(this.croppedImage);
      console.log('file', file);
      this.uploadFile(file);
    }
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }


  savePandalData() {
    this.submitted = true;

  
    if (this.imagePath !== null) {
      this.imageData = this.imgForm.value;
      this.imageData['pandalId'] = this.mobile;
      this.imageData['imagePath'] = this.imagePath;
      this.imageData['consent'] = this.npReq;

      this.api.savePandalData(this.imageData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            alert('Pandal has been uploaded successfully!');
            this.refresh();
            //this.router.navigateByUrl('/user-dashboard');
          } else {
           
            alert(this.respData.message);

          
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
         // this.startLoader = false;
          console.log(err);
          this.imgStatus = false;
        }
      );
    } else {
      //this.startLoader = false;
      alert('Please upload Selfie');
      this.imgStatus = false;
    }
  }


  chooseCollection(event: any) {
    this.selectedCollection  = event.target.value;
    alert(this.selectedCollection)
  }


  onDoorstepChange(value) {
    console.log(' Value is : ', value);

    if (value == 'no') {
      this.isDoorstepNo = true;
    } else {
      this.isDoorstepNo = false;
    }
  }

  onChangeState(event, cat: any) {
    if (event.target.checked) {
      this.npReq = cat;
      //alert(cat);
    } else {
      this.npReq = 'No';
      //alert(this.npReq);
    }

    // this.tempArr.brands.push(cat);
  }
 

  

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('city');
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }


  sendOtp() {
  
    this.mobile = this.imgForm.get('userPhone').value;
    this.sendMobileNo['token'] = this.recaptchaStr;
    this.sendMobileNo['phone'] = this.mobile;
  
    if (this.imgForm.get('userPhone').invalid) {
      alert('Please enter 10 digit valid Mobile Number');
    } else {
     // this.startLoaderOTP = true;
      this.api.sendOtp(this.sendMobileNo).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.sentotp = true;
            //this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
            this.sendButton = 'Resend';
          } else {
            this.sentotp = false;
           // this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
          }
        },
        (err) => {
         // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
   
  }
  
  verifyOtp(data: any) {
    this.otpdata['phone'] = this.imgForm.get('userPhone').value;
    this.otpdata['otp'] = data;
  
    if (this.otpdata['phone'] !== '' && this.otpdata['otp'] !== '') {
      this.api.verifyOtp(this.otpdata).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
            this.sentotp = false;
            this.sendButton = 'Verified';
            this.isSubmit = true;
  
            this.imgForm.get('userPhone').disable();
            this.verified = false;
            this.v = false;
          } else {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      alert('Please enter Valid OTP');
    }
  }
  
  
    onOtpClick(captchaRef: any): void {
      if (this.recaptchaStr) {
        captchaRef.reset();
      }
      captchaRef.execute();
    }
  
    public resolved(captchaResponse: string): void {
      this.recaptchaStr = captchaResponse;
      if (this.recaptchaStr) {
        this.sendOtp();
      }
    }
  
    hideOTP() {
      this.sentotp = false;
    }

  

}
