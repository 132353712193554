<div class="d-flex" id="wrapper" style="background-color:#ededed;">

  <!-- Sidebar -->
  <div class="side-navbar border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">

      <div class="dashboard-logo">
        <a routerLink="/home" class="">
          <img src="assets/img/times-bappa-logo.png" loading="lazy">
        </a>
      </div>
    </div>

    <div class="list-group list-group-flush">
      <a routerLink="/user-dashboard" class="list-group-item list-group-item-action">Dashboard</a>
      <a routerLink="/bappa" class="list-group-item list-group-item-action">Bappa Contest</a>
      <a routerLink="/selfie" class="list-group-item list-group-item-action">Selfie With Bappa Contest</a>
      <a routerLink="/paintings" class="list-group-item list-group-item-action">Paintings & Drawings Contest</a>
      <a routerLink="/pandal" class="list-group-item list-group-item-action">Pandal Contest</a>
      <a href="https://theartofindia.in/collections/ganeshotsav" target="_blank" class="list-group-item list-group-item-action">Art Gallery</a>
      <a routerLink="/live-corner" class="list-group-item list-group-item-action">Live Corner</a>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="dashboard-navbar">
      <a class="btn" id="menu-toggle"><i class="bx bx-menu"></i></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dashboard-navbar justify-content-end text-right">
        <button style="background: #7f736d;" mat-mini-fab aria-label="Logout" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
       
      </div>
    </nav>
    <main>

      <!-- ======= Breadcrumbs Section ======= -->
      <section class="breadcrumbs" style="margin-top:0 !important">
        <div class="container-fluid">

          <div class="d-flex justify-content-between">
            <h2>Welcome {{userName}}</h2>
            <ol>
              <li><a routerLink="/home">Home</a></li>
              <li>Create Bappa</li>
            </ol>
          </div>

        </div>
      </section><!-- End Breadcrumbs Section -->

      <section class="inner-page" style="padding:0 !important">

        <!-- ======= Main Section ======= -->
        <!-- ======= Chefs Section ======= -->
        <section id="chefs" style="padding:0 !important;">
          <div class="container">

          
            <div class="row d-flext justify-content-center">

              <div class="col-lg-6 col-md-6">
                
                  <mat-card class="shadow corner bg-light mt-3">
               
                  <div class="row mt-3">

                    <div class="col-lg-8 col-md-8 col-sm-12 canvas-area">
                      <div class="row d-flext justify-content-center">
                        <canvas #myCanvas id="myCanvas" height="300" width="300" class="canvas"></canvas>
                      </div>

                    </div>



                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="row d-flext justify-content-center">
                        <div class="example-button-row">
                          <div class="example-flex-container">
                            <div class="example-button-container justify-content-center text-center" style="padding:0 8px">
                              <button style="background: #fe6717;" mat-mini-fab aria-label="Bring to forward" (click)="bringForward()">
                                <mat-icon>flip_to_front</mat-icon>
                              </button>
                              <p style="font-size: 12px; color: black; font-weight: 500">Bring To Front</p>
                            </div>
                            
                            <div class="example-button-container justify-content-center text-center" style="padding:0 8px">
                              <button style="background: #fe6717;" mat-mini-fab aria-label="Remove" (click)="remove()">
                                <mat-icon>delete</mat-icon>
                              </button>
                              <p style="font-size: 12px; color: black; font-weight: 500">Remove Item</p>
                            </div>

                            <div class="example-button-container justify-content-center text-center" style="padding:0 8px">
                              <button style="background: #fe6717;" mat-mini-fab aria-label="Send to backward" (click)="sendBackwards()">
                                <mat-icon>flip_to_back</mat-icon>
                              </button>
                              <p style="font-size: 12px; color: black; font-weight: 500">Send To Back</p>
                            </div>

                            <div class="example-button-container justify-content-center text-center" style="padding:0 8px">
                              <button style="background: #fe6717;" mat-mini-fab aria-label="Save Bappa" (click)="uploadBappa()">
                                <mat-icon>save</mat-icon>
                              </button>
                              <p style="font-size: 12px; color: black; font-weight: 500">Save Bappa</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    

                    </div>




                  </div>


              

                
                  <div class="row mb-3">

                    <div class="cd-panel cd-panel--from-right js-cd-panel-main">
                      <header class="cd-panel__header">
                        <h3>{{panelTitle}}</h3>
                        <a href="#0" class="cd-panel__close js-cd-close">Close</a>
                      </header>

                      <div class="cd-panel__container">
                        <div class="cd-panel__content">
                          <app-bappa-background (newItemEvent)="setBackground($event)" *ngIf="backgroundselect">
                          </app-bappa-background>
                          <app-bappa-idol (newItemEvent)="setIdol($event)" *ngIf="idolselect"></app-bappa-idol>
                          <app-bappa-garland (newItemEvent)="setGarland($event)" *ngIf="garlandselect">
                          </app-bappa-garland>
                          <app-bappa-flower (newItemEvent)="setFlower($event)" *ngIf="flowerselect"></app-bappa-flower>
                          <app-bappa-decor (newItemEvent)="setDecor($event)" *ngIf="decorselect"></app-bappa-decor>
                          <app-bappa-sweets (newItemEvent)="setSweets($event)" *ngIf="sweetsselect"></app-bappa-sweets>
                          <app-bappa-fruits (newItemEvent)="setFruits($event)" *ngIf="fruitsselect"></app-bappa-fruits>
                        </div> 
                      </div> 
                    </div> 




                  </div>

                  <div class="row mt-3">

                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                    (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                      <a href="#0" class="cd-btn js-cd-panel-trigger justify-content-center text-center" style="text-decoration: none;"data-panel="main">
                        <img src="{{ slide.img }}" alt="" width="100%" (click)="getObject(slide.name)">
                        <p style="font-size: 12px; color: black; font-weight: 500">{{slide.name | titlecase}}</p>
                      </a>
                    </div>
                  </ngx-slick-carousel>
                  </div>
                </mat-card>

                

                


              </div>




            </div>


          </div>
        </section>

        <!-- <div class="container">
          <div class="section-title">
            <h2>Bappa<span> Contest</span></h2>
            <p>Bappa Contest Submission has been closed</p>
          </div>
        </div> -->
      </section>
    </main>
    <!-- /#page-content-wrapper -->
  </div>