import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import { ActivatedRoute } from '@angular/router';
import {Title, Meta} from '@angular/platform-browser'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bappa-contest-single',
  templateUrl: './bappa-contest-single.component.html',
  styleUrls: ['./bappa-contest-single.component.scss']
})
export class BappaContestSingleComponent implements OnInit {
  title: string = "Bappa Contest";
  respData: any = {};
  userData: any = {};
  newUserData:any = {}
  hidden = false;

  userName: string;

  loggedIn:boolean = false;
  public userId;
  public token;

  displayName: string = "";

  voterData: any = {};

  dataFilter: any = { "userId": '', "paintingsTitle": ''};


  notEmptyData = true;
  notscrolly = true;
  nextToken:any ="";
  
  getData:any = {}

  uId:any;
  image:any;

  uName:any;

  imgUrl:string;
  city:String="";
  environment= environment;

  constructor(private api:ApiService, private activatedRoute: ActivatedRoute, private mTitle:Title, private metaTagService:Meta) { }

  ngOnInit(): void {
   
    this.city = localStorage.getItem ('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }

    this.uId = this.activatedRoute.snapshot.params.id;
    this.image= this.activatedRoute.snapshot.params.image;
    console.log(this.uId, this.image);

    // if(this.uId != null && this.image != null) {
      this.getSingleBappaData()
    // }

    this.imgUrl = "https://s3.ap-south-1.amazonaws.com/www.timesbappa.com-data/bappaidol/"+this.image;
    this.addTag(this.imgUrl);

    
  }

  addTag(img:any) {
    this.metaTagService.addTag({ name: 'description', content: 'Vote For My Bappa' });
    this.metaTagService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaTagService.addTag({ property: 'og:title', content: 'Times Bappa - Bappa Contest' });
    this.metaTagService.addTag({ property: 'og:image', content: img });
    this.metaTagService.addTag({ property: 'og:image:secure_url', content: img });
  }

  


  getSingleBappaData() {
    // if (this.token != null && this.userId != null) {
      this.getData = { uId:this.uId , image:this.image};

      this.api.getSingleBappaData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          this.uName = this.userData[0].userName



          console.log(this.userData)

        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }


  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }



  voteNow(id:any, name:string, img:any){
    console.log(this.loggedIn)
    if(this.loggedIn === true){
      this.voterData['voterId'] = this.userId;
      this.voterData['token'] = this.token;
      this.voterData['userId'] = id;
      this.voterData['userName'] = name;
      this.voterData['imagePath'] = img;

      this.api.voteForBappa(this.voterData).subscribe(
        (resp) => {
          //alert('working');
          this.respData = resp;
          if (this.respData.success == true) {
            alert(this.respData.message);
           this.refresh();
          } else {
           // this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //alert('error');
         // this.startLoader = false;
          console.log(err);
        }
      );
      
    }else{
      alert("plaese login for voting");
    } 
  }

  refresh(): void {
    window.location.reload();
  }

}
