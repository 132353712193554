<!-- ======= Header ======= -->
<header class="fixed-top d-flex align-items-center" id="header">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
      <div class="me-auto">
        <!-- <h1><a href="home.html">Times Bappa</a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
        <div class="main-logo">
          <img src="assets/img/times-bappa-logo.png" loading="lazy">
          <!-- <img src="assets/img/timesnft-logo.png" style="width:90%; max-width:180px"loading="lazy"> -->
        </div>
      </div>
      <nav id="navbar" class="order-last order-lg-0 navbar">
        <ul>
          <!-- <li class="nft_logo_web"> <img src="assets/img/timesnft-logo.png" loading="lazy"></li> -->
          <li><a class="nav-link scrollto active" routerLink="/">Home</a></li>
         
        
		  <li><a class="nav-link scrollto" routerLink="/user-dashboard">Create Bappa</a></li>
		  <!-- <li><a class="nav-link scrollto" routerLink="/art-gallery">Art Gallery <sup class="blink_me">New</sup></a> -->
        <li><a class="nav-link scrollto" href="https://theartofindia.in/collections/ganeshotsav" target="_blank">Art Gallery <sup class="blink_me">New</sup></a>
        
        <!-- <span class="navelink-img"><img src="assets/img/new_icon4.png"></span></li> -->
		  
		  <li class="dropdown"><a href="#" class="nav-link scrollto"><span>Contests</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a class="nav-link" routerLink="/bappa-contest">Bappa Contest</a></li>
              <li><a class="nav-link" routerLink="/painting-contest">Paintings Contest<sup class="blink_me"><span>New</span></sup></a></li>
              <li><a class="nav-link" routerLink="/pandal-contest">Pandal Contest<sup class="blink_me"><span>New</span></sup></a></li>
              <li><a class="nav-link" routerLink="/selfie-contest">Selfie with Bappa Contest<sup class="blink_me"><span>New</span></sup></a></li>
            </ul>
          </li>
		  <li><a class="nav-link scrollto" routerLink="/live-corner">Live Corner<sup class="blink_me">New</sup></a></li>
      <li><a class="nav-link scrollto" (click)="logout()" id="logout_mob" *ngIf="loggedIn">Logout</a></li>
          
        </ul>
       <i id="mobile-nav-toggle" class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->
        
     <div class="book-a-table-btn scrollto" *ngIf="!loggedIn">
      <a style="text-decoration: none !important; color: #fff !important" routerLink="/login">Login </a>
      <a style="text-decoration: none !important; color: #fff !important" routerLink="/register">/ Register</a>
     </div>

      
    </div>

    <div class="align-items-end" style="padding-right:20px;" id="logout_web" *ngIf="loggedIn">
      <a class="book-a-table-btn scrollto" style="text-decoration: none !important;" routerLink="/user-dashboard">My Bappa</a>
      <a class="book-a-table-btn scrollto" style="text-decoration: none !important;" (click)="logout()">Logout</a>
      <!-- <button style="background: #7f736d;" mat-mini-fab aria-label="Logout" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button> -->
 
    </div>
  </header><!-- End Header -->