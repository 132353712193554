import {
  Component,
  OnInit,
} from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
// import {
//   HttpEvent,
//   HttpEventType,
//   HttpErrorResponse,
// } from '@angular/common/http';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title: string = "Login"
  loginForm: FormGroup;
  submittedLogin = false;
  loginData:any;
  respData:any;
  uId:any;
  lp:any;
  ip:any;
  city:string="";

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private api:ApiService) { }

  ngOnInit(): void {
    this.city = localStorage.getItem('city');
    this.uId = this.route.snapshot.paramMap.get('id');
    this.lp = this.route.snapshot.paramMap.get('landingPage');
    this.ip = this.route.snapshot.paramMap.get('imagePath');

    console.log('id',this.uId)
    console.log('lp',this.lp)

    

    this.loginForm = this.fb.group({
      loginPhone: ['',[Validators.required, Validators.pattern('^((\\-?))?[0-9]{10}$')],],
      loginPassword: ['', [Validators.required]],
    });
  }
  get lf() {
    return this.loginForm.controls;
  }

   login() {
    this.submittedLogin = true;

    if (this.loginForm.invalid) {
      //   alert('form validation');
      // this.v = true;
    //  this.startLoader = false;
      return;
    }

    this.loginData = this.loginForm.value;

    this.api.login(this.loginData).subscribe(
      (resp) => {
        //alert('working');
        this.respData = resp;
        if (this.respData.success == true) {
         
         // this.startLoader = false;
          localStorage.setItem('userId', this.respData.userId);
          localStorage.setItem('userName', this.respData.userName);
          localStorage.setItem('token', this.respData.token);

          if(this.lp != null){
            if(this.uId != null){
              this.router.navigate([this.lp+'/'+this.uId]);
            }else if(this.ip != null){
              this.router.navigate([this.lp+'/'+this.uId+'/'+this.ip]);
            }else{
              this.router.navigate(this.lp);
            }

          }else{
            console.log('if null', this.lp)
            console.log('if null', this.uId)
            this.router.navigateByUrl('/user-dashboard');
          }

          
        } else {
         // this.startLoader = false;
          alert(this.respData.message);
          console.log('error' + this.respData.message);
        }
      },
      (err) => {
        //alert('error');
       // this.startLoader = false;
        console.log(err);
      }
    );

  }

  gotoRegister(){
    if(this.lp != null){
      if(this.uId != null){
        this.router.navigate(['/register', { landingPage:this.lp, id: this.uId }]);
      }else if(this.ip != null){
        this.router.navigate(['/register', { landingPage:this.lp, id: this.uId, imagePath:this.ip }]);
      }else{
        this.router.navigate(['/register', { landingPage:this.lp }]);
      }

    }else{
      console.log('if null', this.lp)
      console.log('if null', this.uId)
      this.router.navigateByUrl('/register');
    }

    
  }

}
