import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SwiperOptions } from 'swiper';
declare const myFunction: any;
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  city: string = "";
  welcome: boolean = false;


  environment = environment;
  respBappaData: any = {};
  bappaData: any = {};

  respPaintingData: any = {};
  paintingData: any = {};

  respPandalData: any = {};
  pandalData: any = {};

  respSelfieData: any = {};
  selfieData: any = {};

  pandalImgUrl: string = environment.image_url + "pandalData/"
  paintingsImgUrl: string = environment.image_url + "paintings/"

  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 50,
    autoplay: true,
    speed: 500,
    longSwipesMs: 5000

  };

  bappaCreated: string;

  createBappa: string = "";
  showBappa: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService) { }

  ngOnInit(): void {

    this.city = localStorage.getItem('city');

    if (this.city == null) {
      console.log('city', this.city, this.city == null);
      window.location.href = '/welcome';
    } else {
      this.bappaCreated = localStorage.getItem('bappaCreated');

      if (this.bappaCreated == null) {
        this.createBappa = "Create Bappa"
      } else {
        this.createBappa = "My Bappa"
      }

      this.getTop3Bappa();
      this.getTop3Pandals();

      this.loadJsMain("./assets/js/main.js");
    }
  }

  loadJsMain(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  getTop3Bappa() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

    this.api.getTop3Bappa().subscribe(
      (resp) => {
        this.respBappaData = resp;
        this.bappaData = this.respBappaData.data;

        console.log(this.bappaData)

      },
      (err) => {
        // this.startLoaderOTP = false;
        console.log(err);
      }
    );
  }

  getTop3Paintings() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

    this.api.getTop3Paintings().subscribe(
      (resp) => {
        this.respPaintingData = resp;
        this.paintingData = this.respPaintingData.data;

        console.log(this.paintingData)

      },
      (err) => {
        // this.startLoaderOTP = false;
        console.log(err);
      }
    );
  }

  getTop3Pandals() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

    this.api.getTop3Pandals().subscribe(
      (resp) => {
        this.respPandalData = resp;
        this.pandalData = this.respPandalData.data;

        console.log(this.pandalData)


      },
      (err) => {
        // this.startLoaderOTP = false;
        console.log(err);
      }
    );
  }

  getTop3Selfie() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

    this.api.getTop3Selfie().subscribe(
      (resp) => {
        this.respSelfieData = resp;
        this.selfieData = this.respSelfieData.data;

        console.log(this.selfieData)

      },
      (err) => {
        // this.startLoaderOTP = false;
        console.log(err);
      }
    );
  }

  ngAfterViewInit()
  {
    var that = this;
    setTimeout(function () {
      debugger;
      that.showBappa = true;
      that.getTop3Selfie();
      that.getTop3Paintings();
    }, 3000);
  }

}
