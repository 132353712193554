<app-inner-header [title]="title"></app-inner-header>
<main id="main">

  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-end align-items-right">
        <!-- <h2>Registration</h2> -->
        <ol>
          <li><a routerLink="/">Home</a></li>
          <li>{{title}}</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->


  <section class="inner-page parallax" style="padding:10px !important;">
    <div class="container" style="position: relative;">
      <!-- ======= Login Section ======= -->


      <section id="book-a-table" class="book-a-table" style="padding:10px !important;">
        <div class="container">

          <div class="row d-flex justify-content-center text-center">

            <div class="col-lg-6 col-lg-6 col-sm-12">


              <div class="section-title">
                <h2>User <span>Registration</span></h2>

              </div>

              <form [formGroup]="regForm" class="php-email-form">
                <div class="row">
                  <div class="col-lg-6 col-md col-sm-6 form-group" data-for="name">
                    <input id="userName" name="name" placeholder="Full Name" class="form-control" type="text"
                      [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" formControlName="userName"
                      [(ngModel)]="inputvalue" (ngModelChange)="changeToSentenceCase($event)" />
                    <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                      <div *ngIf="f.userName.errors.required">* Name is required</div>
                      <div *ngIf="f.userName.errors.pattern">
                        * Name must be a character value
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md col-sm-6 form-group" data-for="email">
                    <input id="userEmail" name="name" placeholder="Email" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.userEmail.errors }" formControlName="userEmail" />
                    <div *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                      <div *ngIf="f.userEmail.errors.required">
                        * Email is required
                      </div>
                      <div *ngIf="f.userEmail.errors.email">
                        * Please Enter Valid Email
                      </div>
                    </div>
                  </div>



                  <div class="col-lg-6 col-md col-sm-6 form-group" data-for="password">
                    <input id="password" name="password" placeholder="Password" type="password" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">
                        * Password is required
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md col-sm-6 form-group" data-for="cPassword">
                    <input id="cPassword" name="cPassword" placeholder="Confirm Password" class="form-control"
                      type="password" [ngClass]="{'is-invalid': submitted && f.cPassword.errors}"
                      formControlName="cPassword" />
                    <div *ngIf="submitted && f.cPassword.errors" class="invalid-feedback">
                      <div *ngIf="f.cPassword.errors.required">
                        * Confirm Password is required
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group" data-for="phone">
                    <input id="userPhone" name="userPhone" placeholder="Mobile Number" (keypress)="hideOTP()"
                      class="form-control" type="text" [ngClass]="{ 'is-invalid': submitted && f.userPhone.errors }"
                      formControlName="userPhone" />
                    <div *ngIf="submitted && f.userPhone.errors" class="invalid-feedback">
                      <div *ngIf="f.userPhone.errors.required">
                        * Mobile Number is required
                      </div>
                      <div *ngIf="f.userPhone.errors.pattern">
                        * Please, Enter 10 digit Mobile Number
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group" style="padding-left: 0px !important">
                    <Button class="btn btn-secondary display-4"
                      [ngClass]="[isSubmit? 'btn btn-secondary display-4': 'btn btn-warning display-4']"
                      (click)="onOtpClick(captchaRef)" [disabled]="!verified">{{ sendButton }}</Button>
                  </div>

                  <div class="col-lg-12 col-12 form-group" *ngIf="sentotp" style="color: #000; text-align: left">
                    {{ messageMobile }}
                  </div>

                  <!-- <div *ngIf="startLoaderOTP" class="spinner-border text-dark" role="status" style="margin: auto">
                <span class="sr-only">Loading...</span>
            </div> -->
                  <div class="col-lg-6 col-md-6 col-sm-12 form-group" data-for="otp" *ngIf="sentotp">
                    <input class="form-control" data-form-field="otp" id="otp-form4-2" name="otp"
                      [(ngModel)]="userInfo.otp" placeholder="Enter OTP" type="text" formControlName="otp" />

                    <div *ngIf="verifyotp" class="col-lg-12 col-12 form-group" style="color: #000; text-align: left">
                      {{ messageOtp }}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 form-group" style="padding-left: 0 !important"
                    *ngIf="sentotp">
                    <Button class="btn btn-secondary display-4"
                      [ngClass]="[isSubmit? 'btn btn-secondary display-4': 'btn btn-warning display-4']"
                      (click)="verifyOtp(userInfo.otp)" [disabled]="!verified" value="Verify">Verify</Button>
                  </div>

                </div>

                <div class="col-12" style="padding-top: 15px">
                  <p class="mbr-text mbr-fonts-style mb-4 display-7" style="
                      margin-bottom: 12px !important;
                      text-align: left !important;
                    ">
                    Do you get a copy of The Times of India at your doorstep?
                  </p>
                </div>
                <div class="col-12 form-group" data-for="checkbox" style="margin-bottom: 0px; text-align: left !important; display: flex; justify-content: left; align-items: center;">
                  <input id="yes" type="radio" value="yes" name="newsaperAtDoorstep"
                    formControlName="newsaperAtDoorstep" (change)="onDoorstepChange($event.target.value)" />
                  <label for="yes" style="padding-left: 4px">Yes</label>
                </div>
                <div class="col-12 form-group" data-for="checkbox" style="margin-bottom: 0px; text-align: left !important; display: flex; justify-content: left; align-items: center;">
                  <input id="no" type="radio" value="no" name="newsaperAtDoorstep" formControlName="newsaperAtDoorstep"
                    (change)="onDoorstepChange($event.target.value)" />
                  <label style="padding-left: 4px" for="no">No</label>
                </div>


                <div class="row justify-content-center mt-2" style="margin-bottom: 0px; text-align: left !important; display: flex; justify-content: left;">

                  <!-- <div class="col-1 form-group" data-for="checkbox" *ngIf="isDoorstepNo">
                   
                  </div> -->

                  <div class="col-12 form-group" data-for="checkbox" *ngIf="isDoorstepNo">
                    
                    <label for="npReq">
                      <input type="checkbox" value="yes" id="npReq" (change)="onChangeState($event, 'yes')" />
                      I would like to get a copy of The Times of India. Kindly
                      contact me.</label>
                  </div>
                </div>

                <div class="row justify-content-center mt-2" style="margin-bottom: 0px; text-align: left !important; display: flex; justify-content: left;">
                  <!-- <div class="col-1 col-sm-1 form-group" data-for="checkbox"
                    style="margin-bottom: 0px; text-align: left; max-width: 4% !important;">
                    <input type="checkbox" value="yes" id="con" (change)="onChangeCon($event, 'yes')" />
                  </div> -->

                  <div class="col-12 form-group check" data-for="checkbox" style="margin-bottom: 0px; text-align: left; color: black">
                   
                      <label for="con">
                        <input type="checkbox" value="yes" id="con" (change)="onChangeCon($event, 'yes')" />
                        *I agree to the Terms & Conditions, Privacy Policy and Cookie Policy and receive marketing text messages, emails and calls from Bennett Coleman & Co. Ltd to the contact details I have provided.
                      </label>
                  </div>
                </div>

                <div class="col-lg-12 col-md col-12 form-group" data-for="re-captcha">
                  <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
                </div>

                <div class="row col-lg-12 d-flex justify-content-center text-center">

                  <div class="text-center mt-3 mb-3"><button type="submit"><a (click)="register()"
                        style="color:#fff !important; text-decoration: none !important; padding:0 2%;"> Register
                      </a></button></div>
                  <div class="text-center mt-3 mb-3">
                    <p><strong><a style="cursor: pointer;" class="nav-link scrollto" routerLink="/login">Log in!</a></strong></p>
                  </div>


                </div>
              </form>



            </div>


          </div>


          <div class="row col-lg-12 d-flex justify-content-center">

            <div class="text-center col-lg-4 mt-3 mb-3">
              <a class="btn-dashboard" style="color:#fff !important; text-decoration: none !important; cursor: pointer;"
                routerLink="/pandal-registeration">Pandal Registration</a>
            </div>

          </div>


          

        </div>
      </section><!-- End Login Section -->
      <div class="row d-flex justify-content-center">

        <!-- <div class="col-lg-6 col-md-6">
            <div class="member">
              <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                  style="color:#fff">Upload Selfie</a></button>
            </div>
          </div> -->
        <div class="row mb-3 d-flex justify-content-center text-center" *ngIf="city != 'Mumbai'">

          <!-- <div class="col-lg-4 col-sm-12">
            <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
              <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                  style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
              <div class="col-12 col-lg-12 sponsor-grid-left">
                <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                  <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy" />
                </a>

              </div>
            </div>

          </div> -->



          <!-- <div class="col-lg-8 col-sm-12">

            <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
              <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
              </p>


              <div class="row mb-3 d-flex justify-content-center text-center">
                <div class="col-4 col-lg-3 sponsor-grid mt-3">
                  <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                    <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy"/>
                  </a>

                </div>

                <div class="col-4 col-lg-3 sponsor-grid">
                  <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                    <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                  </a>

                </div>

                <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                  </a>

                </div>

                <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                  </a>

                </div>





              </div>




            </div>


          </div> -->




        </div>


      </div>
    </div>
  </section>

</main><!-- End #main -->

<app-footer></app-footer>