import {
  Component,
  OnInit,
} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  title: string = "Registration"
  regForm: FormGroup;

  submitted = false;

  sentotp: boolean = false;
  verifyotp: boolean = false;
  verified: boolean = true;

  v: boolean = false;

  messageOtp: String = '';
  messageMobile: String = '';
  sendButton: String = 'Send OTP';
  isSubmit: boolean = false;

  recaptchaStr = '';
  respData: any = {};

  mobile: any = '';
  otpdata: any = {};
  sendMobileNo: any = {};
  userData: any;

  userInfo = { mobileNumber: '', mobileNumber1: '', otp: '' };

  resp: any;

  uId:any;
  lp:any;
  ip:any;

  inputvalue = ''

  isDoorstepNo: boolean = false;
  npReq: String = '';
  con:String = '';
  city: String = ''

  
  changeToSentenceCase(event) {

      this.inputvalue = event.replace(/\b\w/g, 
          event => event.toLocaleUpperCase());

  }

  constructor(private api:ApiService, private fb: FormBuilder, private router:Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {

    this.city = localStorage.getItem('city');

    this.uId = this.route.snapshot.paramMap.get('id');
    this.lp = this.route.snapshot.paramMap.get('landingPage');
    this.ip = this.route.snapshot.paramMap.get('imagePath');


    this.regForm = this.fb.group({
      userName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      userEmail: ['', [Validators.required, , Validators.email]],
      password: ['', [Validators.required]],
      cPassword: ['', [Validators.required]],
      userPhone: [
        '',
        [Validators.required, Validators.pattern('^((\\-?))?[0-9]{10}$')],
      ],
      otp: ['', [Validators.required]],
      newsaperAtDoorstep: ['', [Validators.required]]
    });
  }
  get f() {
    return this.regForm.controls;
  }

  register() {

    this.submitted = true;

    if (this.regForm.invalid) {
      //alert('form validation');
      alert('Please fill all details');
      this.v = true;
     // this.startLoader = false;
      return;
    }

    if (this.verified == false) {
      // alert("2")
      this.userData = this.regForm.value;
      this.userData['phone'] = this.mobile;
      let np = this.regForm.get('newsaperAtDoorstep').value;
      if (np == 'no') {
        this.userData['TOICopyRequired'] = this.npReq;
      }

      this.userData['consent'] = this.con;
      this.userData['city'] = this.city;
      
      
      console.log(this.userData)
      this.api.signup(this.userData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
           
           // this.startLoader = false;
            localStorage.setItem('userId', this.respData.userId);
            localStorage.setItem('userName', this.respData.userName);
            localStorage.setItem('token', this.respData.token);

            if(this.lp != null){
              if(this.uId != null){
                this.router.navigate([this.lp+'/'+this.uId]);
              }else if(this.ip != null){
                this.router.navigate([this.lp+'/'+this.uId+'/'+this.ip]);
              }else{
                this.router.navigate(this.lp);
              }
  
            }else{
              console.log('if null', this.lp)
              console.log('if null', this.uId)
              this.router.navigateByUrl('/user-dashboard');
            }

          } else {
           //// this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //this.startLoader = false;
          console.log(err);
        }
      );
    } else {
      //this.startLoader = false;
      this.v = true;
    }
  }




  sendOtp() {

    this.mobile = this.regForm.get('userPhone').value;
    this.sendMobileNo['token'] = this.recaptchaStr;
    this.sendMobileNo['phone'] = this.mobile;

    if (this.regForm.get('userPhone').invalid) {
      alert('Please enter 10 digit valid Mobile Number');
    } else {
     // this.startLoaderOTP = true;
      this.api.sendOtp(this.sendMobileNo).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.sentotp = true;
            //this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
            this.sendButton = 'Resend';
          } else {
            this.sentotp = false;
           // this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
          }
        },
        (err) => {
         // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
   
  }

  verifyOtp(data: any) {
    this.otpdata['phone'] = this.regForm.get('userPhone').value;
    this.otpdata['otp'] = data;

    if (this.otpdata['phone'] !== '' && this.otpdata['otp'] !== '') {
      this.api.verifyOtp(this.otpdata).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
            this.sentotp = false;
            this.sendButton = 'Verified';
            this.isSubmit = true;

            this.regForm.get('userPhone').disable();
            this.verified = false;
            this.v = false;
          } else {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      alert('Please enter Valid OTP');
    }
  }


  onOtpClick(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }

  public resolved(captchaResponse: string): void {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.sendOtp();
    }
  }

  hideOTP() {
    this.sentotp = false;
  }


  onDoorstepChange(value) {
    console.log(' Value is : ', value);

    if (value == 'no') {
      this.isDoorstepNo = true;
    } else {
      this.isDoorstepNo = false;
    }
  }

  onChangeState(event, cat: any) {
    if (event.target.checked) {
      this.npReq = cat;
      //alert(cat);
    } else {
      this.npReq = 'No';
      // alert(this.npReq);
    }

    // this.tempArr.brands.push(cat);
  }

  onChangeCon(event, cat: any) {
    if (event.target.checked) {
      this.con = cat;
      //alert(cat);
    } else {
      this.con = 'No';
      // alert(this.npReq);
    }

    // this.tempArr.brands.push(cat);
  }


}
