import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pandal-contest-single',
  templateUrl: './pandal-contest-single.component.html',
  styleUrls: ['./pandal-contest-single.component.scss']
})
export class PandalContestSingleComponent implements OnInit {

  title: string = "Pandal Contests";
  respData: any = {};
  userData: any = {};
  newUserData:any = {}
  hidden = false;

  userName: string;

  loggedIn:boolean = false;
  public userId;
  public token;

  displayName: string = "";

  voterData: any = {};


  dataFilter: any = { "userId": '', "pandalTitle": ''};


  notEmptyData = true;
  notscrolly = true;
  nextToken:any ="";
  
  getData:any = {}

  uId:any;

  uName:any;
  city:string="";
  //currentUrl:any = "";
  environment = environment;

  constructor(private api:ApiService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //this.currentUrl = this.router.url;
    this.city = localStorage.getItem('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }

    this.uId = this.activatedRoute.snapshot.params.id;
    console.log(this.uId);

    //if(this.uId != null) {
      this.getSinglePandalData()
    //}
    
    
  }


  getSinglePandalData() {
    // if (this.token != null && this.userId != null) {
      this.getData = { uId:this.uId };

      this.api.getSinglePandalData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;
          this.uName = this.userData[0].userName

          console.log(this.userData)

        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }

  
  
  

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  voteNow(id:any, name:any, location:string, img:any){
    console.log(this.loggedIn)
    if(this.loggedIn === true){
      this.voterData['voterId'] = this.userId;
      this.voterData['token'] = this.token;
      this.voterData['pandalId'] = id;
      this.voterData['pandalName'] = name;
      this.voterData['location'] = location
      this.voterData['imagePath'] = img;

      this.api.voteForPandal(this.voterData).subscribe(
        (resp) => {
          //alert('working');
          this.respData = resp;
          if (this.respData.success == true) {
            alert(this.respData.message);
           this.refresh();
          } else {
           // this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //alert('error');
         // this.startLoader = false;
          console.log(err);
        }
      );
      
    }else{
      alert("plaese login for voting");
      this.router.navigate(['/login', { landingPage:"/pandal-contest", id: id }]);
    } 
  }

  refresh(): void {
    window.location.reload();
  }

}
