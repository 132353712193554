<app-inner-header [title]="title"></app-inner-header>
<main id="main">

    <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex example-flex-container align-justify align-items-center">

        <div class="paowered"><span style="padding:0 5px; font-size:19px;">Powered by</span> <img src="assets/img/artzolo_final_logo.jpg" loading="lazy"></div>
        <ol>
          <li><a routerLink="/">Home</a></li>
          <li>{{title}}</li>
          
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->

    <section class="inner-page">
      <div class="container" style="position: relative;">
        <div class="form-group col-md-3">
          <input mat-input type="text" [(ngModel)]="dataFilter.artist" placeholder="Search by artist" class="form-control" type="text">
        </div>


          <div class="row d-flex justify-content-left">
             
            <div class="product col-lg-3 col-md-3" style="margin:30px 0" *ngFor="let user of (userData | slice: lowValue : highValue | filterBy: dataFilter); let i = index">

                <div class="card shadow" (click)="viewItem(user.largeImageUrl, user.name, user.artist,user.url)">
                  
                  <div class="product-img">
                        <img src='{{user.imageUrl}}' alt="" loading="lazy">
                        <div class="product-label">
                            <span class="sale">{{user.shape}}</span>
                            <span class="new">{{user.amount | currency:'INR':true}}</span>
                        </div>
                    </div>
                    <div class="product-body">
                        <p class="product-category">{{user.artType}} - {{user.material}}</p>
                       
                        <h3 class="product-price">{{user.title | titlecase}}</h3>
                        <h3 class="product-price"><span style="color: rgba(26, 24, 22, 0.85);">by {{user.artist}} </span></h3>
                        <h4 class="product-name">(Dimension : {{user.dimensions}}) </h4>
                       
                    </div>
                   
                  
                    
                </div>

                <div class="content-overlay"  *ngIf="user.status==='disable'">
                  <div class="content-details fadeIn-bottom">
                    <h3 class="content-title">Sold</h3>
                   
                  </div>
                </div> 
               
                <!-- /product -->
                
               
            </div>

            <mat-paginator id=""[length]="userData.length" pageSize=12
               (page)="getPaginatorData($event)" [pageSizeOptions]="[12, 24, 36]"
               showFirstLastButtons="true">
            </mat-paginator>
            

          </div> 

      </div>


      <div class="row d-flex justify-content-center">

        <!-- <div class="col-lg-6 col-md-6">
          <div class="member">
            <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                style="color:#fff">Upload Selfie</a></button>
          </div>
        </div> -->
        <div class="row mb-3 d-flex justify-content-center text-center">

          <!-- <div class="col-lg-4 col-sm-12">
            <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
              <p class="animate__animated animate__fadeInDown display-7"
                style="color:#fe6717; margin-top:10px;"><span
                  style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
              <div class="col-12 col-lg-12 sponsor-grid-left">
                <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                  <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy" />
                </a>
                
              </div>
            </div>
  
          </div> -->
  
         
  
          <!-- <div class="col-lg-8 col-sm-12">
            
            <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
              <p class="display-7 association"><span
                  style="color: rgba(26, 24, 22, 0.85);">In association</span> with</p>
  
  
              <div class="row mb-3 d-flex justify-content-center text-center">
                <div class="col-4 col-lg-3 sponsor-grid mt-3">
                  <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                    <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy"/>
                  </a>
                  
                </div>
  
                <div class="col-4 col-lg-3 sponsor-grid">
                  <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                    <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy" />
                  </a>
                 
                </div>
  
                <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3" style="width:220px !important">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                  </a>
                 
                </div>
                
                <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                  </a>
                 
                </div>
  
                
               
                
  
              </div>
              
  
  
  
            </div>
  
  
          </div> -->
  
  
  
  
        </div>
  

      </div>
    </section>

  </main><!-- End #main -->

<app-footer></app-footer>