import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import { PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-selfie-contest',
  templateUrl: './selfie-contest.component.html',
  styleUrls: ['./selfie-contest.component.css']
})
export class SelfieContestComponent implements OnInit {

  title: string = "Selfie with Bappa Contest";
  respData: any = {};
  userData: any = {};
  newUserData:any = {}
  hidden = false;

  userName: string;
 

  loggedIn:boolean = false;
  public userId;
  public token;

  displayName: string = "";

  voterData: any = {};

  pageIndex:number = 0;
  pageSize:number = 8;
  lowValue:number = 0;
  highValue:number = 8;    
  environment = environment;
  shareOnWeb: any;

  items = [];
  pageOfItems: Array<any>;

  dataFilter: any = { "userId": '', "selfieTitle": ''};
  
  notEmptyData = true;
  notscrolly = true;
  nextToken:any ="";
  
  getData:any = {}

  searchable:boolean = false;

  inputvalue = ''
  city:String="";
  changeToSentenceCase(event) {

      this.inputvalue = event.replace(/\b\w/g, 
          event => event.toLocaleUpperCase());

  }

  constructor(private api:ApiService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.city =localStorage.getItem('city')
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }
    
    this.getAllApprovedSelfie()
    
  }


  getAllApprovedSelfie() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

      this.api.getAllApprovedSelfie().subscribe(
        (resp) => {
          this.respData = resp;
          this.nextToken = this.respData.contToken;
          this.userData = this.respData.data;

          console.log(this.userData)




        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }


  onScroll() {
    if (this.notscrolly && this.notEmptyData) {
      this.notscrolly = false;
      if(this.nextToken !== undefined){
        this.loadNextData();
      }
     }
    }
    
  loadNextData() {
    
    this.getData = { contToken: this.nextToken };



    this.api.getNextApprovedSelfie(this.getData).subscribe(
      (resp) => {
        
        this.respData = resp;

        if (this.respData.length === 0 ) {
          this.notEmptyData =  false;
        }
        this.nextToken = this.respData.contToken;
        this.newUserData = this.respData.data;

        this.userData = this.userData.concat(this.newUserData);

        this.userData = this.userData.sort(
          (low:any, high:any) => +high.voteCount - +low.voteCount
        );
        console.log(this.userData)
      },
      (err) => {
        
        console.log(err);
      }
    );
    
  }


  // public getPaginatorData(event: PageEvent): PageEvent {
  //   this.lowValue = event.pageIndex * event.pageSize;
  //   this.highValue = this.lowValue + event.pageSize;
  //   return event;
  // }


  searchUserSelfie(data:any){
    this.getData = { searchTerm: data };
  
    this.api.searchUserSelfie(this.getData).subscribe(
      (resp) => {
        
        this.respData = resp;
        this.userData = this.respData.data
  
        this.searchable = true;
  
  
      },
      (err) => {
        
        console.log(err);
      }
    );
  }


  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }



  voteNow(id:any, name:string, img:any){
    console.log(this.loggedIn)
    if(this.loggedIn === true){
      this.voterData['voterId'] = this.userId;
      this.voterData['token'] = this.token;
      this.voterData['userId'] = id;
      this.voterData['userName'] = name;
      this.voterData['imagePath'] = img;

      this.api.voteForSelfie(this.voterData).subscribe(
        (resp) => {
          //alert('working');
          this.respData = resp;
          if (this.respData.success == true) {
            alert(this.respData.message);
           this.refresh();
          } else {
           // this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //alert('error');
         // this.startLoader = false;
          console.log(err);
        }
      );
      
    }else{
      alert("plaese login for voting");
      this.router.navigate(['/login', { landingPage:"/selfie-contest", id: id, imagePath:img }]);
    } 
  }

  refresh(): void {
    window.location.reload();
  }

  share(event, story) {
    event.preventDefault();
    event.stopPropagation();
    story.shareOnWeb = true;
  }
  
  closeShareOptions() {
    this.userData.map(element => element.shareOnWeb = false);
  }

}
