<div class="d-flex" id="wrapper">

  <!-- Sidebar -->
  <div class="side-navbar border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">

      <div class="dashboard-logo">
        <a routerLink="/home">
          <img src="assets/img/times-bappa-logo.png" loading="lazy">
        </a>
      </div>
    </div>

    <div class="list-group list-group-flush">
      <a routerLink="/user-dashboard" class="list-group-item list-group-item-action">Dashboard</a>
      <a routerLink="/create-bappa" class="list-group-item list-group-item-action" *ngIf="!created">Create Bappa</a>
      <a routerLink="/edit-bappa" class="list-group-item list-group-item-action" *ngIf="created">Edit Bappa</a>
      <a routerLink="/bappa" class="list-group-item list-group-item-action">Bappa Contest</a>
      <a routerLink="/selfie" class="list-group-item list-group-item-action">Selfie With Bappa Contest</a>
      <a routerLink="/paintings" class="list-group-item list-group-item-action">Paintings & Drawings Contest</a>
      <a routerLink="/pandal" class="list-group-item list-group-item-action">Pandal Contest</a>
      <a href="https://theartofindia.in/collections/ganeshotsav" target="_blank" class="list-group-item list-group-item-action">Art Gallery</a>
      <a routerLink="/live-corner" class="list-group-item list-group-item-action">Live Corner</a>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="dashboard-navbar">
      <a class="btn" id="menu-toggle"><i class="bx bx-menu"></i></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dashboard-navbar justify-content-end text-right">
        <button style="background: #7f736d;" mat-mini-fab aria-label="Logout" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>

      </div>
    </nav>
    <main>

      <!-- ======= Breadcrumbs Section ======= -->
      <section class="breadcrumbs" style="margin-top:0 !important">
        <div class="container-fluid">

          <div class="d-flex justify-content-between">
            <h2>Welcome {{userName}}</h2>
            <ol>
              <li><a routerLink="/home">Home</a></li>
              <li>{{title}}</li>
            </ol>
          </div>

        </div>
      </section><!-- End Breadcrumbs Section -->

      <section class="inner-page" style="padding:0 !important">

        <!-- ======= Main Section ======= -->
        <!-- ======= Chefs Section ======= -->
        <section id="chefs" class="chefs">
          <div class="container" *ngIf="created">
            <!-- <div class="section-title">
               <h2>My<span> Bappa</span></h2>
               <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p>
             </div> -->

            <div class="row d-flex justify-content-center">

              <div class="col-lg-6 col-md-6">

                <mat-card class="example-card">
                  <!-- <mat-card-header>
                   
                    <mat-card-title>My Bappa</mat-card-title>
                    <mat-card-subtitle>bappa idol</mat-card-subtitle>
                  </mat-card-header> -->
                  <img
                    src='{{environment.image_url +"bappaidol/" + userImageData.imagePath}}'
                    alt="Bappa Idol" loading="lazy">

                  <mat-card-actions align="center">
                    <button mat-button><a class="btn-dashboard" style="text-decoration: none !important;"
                        routerLink="/edit-bappa">Edit Bappa</a></button>
                    <button mat-button><a class="btn-dashboard" style="text-decoration: none !important;"
                        (click)="submitBappaForContest(userImageData.imagePath)">Submit for contest</a></button>
                  </mat-card-actions>
                </mat-card>






              </div>


            </div>

          </div>


          <div class="container" *ngIf="!created">
            <div class="section-title">
              <h2>Create<span> Bappa</span></h2>
              <p>You have not created your bappa! Please create!</p>
            </div>

            <div class="row d-flex justify-content-center">

              <div class="col-lg-6 col-md-6">
                <div class="member">
                  <button class="btn btn-secondary"><a routerLink="/create-bappa" class="nav-link"
                      style="color:#fff">Create Bappa</a></button>
                </div>
              </div>





            </div>

          </div>



        </section><!-- End Chefs Section -->

        <div class="row d-flex justify-content-center">

          <!-- <div class="col-lg-6 col-md-6">
              <div class="member">
                <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                    style="color:#fff">Upload Selfie</a></button>
              </div>
            </div> -->
          <div class="row mb-3 d-flex justify-content-center text-center" *ngIf="city != 'Mumbai'">

            <!-- <div class="col-lg-4 col-sm-12">
              <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
                <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                    style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
                <div class="col-12 col-lg-12 sponsor-grid-left">
                  <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                    <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy"/>
                  </a>

                </div>
              </div>

            </div> -->


<!-- 
            <div class="col-lg-8 col-sm-12">

              <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
                <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
                </p>


                <div class="row mb-3 d-flex justify-content-center text-center">
                  <div class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                      <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy" />
                    </a>

                  </div>

                  <div class="col-4 col-lg-3 sponsor-grid">
                    <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                      <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy" />
                    </a>

                  </div>

                  <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" />
                    </a>

                  </div>

                  <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                    </a>

                  </div>





                </div>




              </div>


            </div> -->




          </div>


        </div>

      </section>


    </main>
    <!-- /#page-content-wrapper -->
  </div>