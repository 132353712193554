import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bappa-background',
  templateUrl: './bappa-background.component.html',
  styleUrls: ['./bappa-background.component.css']
})
export class BappaBackgroundComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
      
  }

 

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

}
