import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as $ from 'jquery';

@Component({
  selector: 'app-paintings',
  templateUrl: './paintings.component.html',
  styleUrls: ['./paintings.component.css']
})
export class PaintingsComponent implements OnInit {

  title:string ='Paintings & Drawings'
  public userId;
  public token;

  imgForm: FormGroup;

  userName: string = ''
  userEmail: string = ''
  userPhone: string = ''
  

  getData = {};
  respData: any = {};
  userData: any = {};

  userImageDataArray: any = [];
  userImageData: any = {};
  userImageDataExists: boolean = false;

  imageData:any = {};

  imgStatus:boolean = false;
  clicked:boolean = false;
  imagePath:string ="";
  imageUploaded:boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  submitted = false;

  selectedCollection:String='';
  filteredCategory;

  isDoorstepNo: boolean = false;
  npReq: String = '';

  created: boolean= false

  userVoteDataArray: any = [];
  userVoteData: any = {};
  userVoteDataExists: boolean = false;
  uName: any = "";
  city:string="";
  paintingImagePath: any = "https://timesbappa-uat-data.s3.ap-south-1.amazonaws.com/paintings/";

  constructor(private router:Router, private api:ApiService, private fb:FormBuilder) { }

  ngOnInit(): void {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
 
    this.city = localStorage.getItem('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.router.navigateByUrl('/');
    }

    this.getUserData();
    this.getUserVoteData();

    this.imgForm = this.fb.group({
      paintingsTitle: ['', [Validators.required]],
      createdBy: ['', [Validators.required]],
      aboutPaintings: ['', [Validators.required]]
    });
    
  }

  get f() {
    return this.imgForm.controls;
  }

  getUserData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          this.userName = this.userData.name;
          this.userEmail = this.userData.email;
          this.userPhone = this.userData.phone;
          //this.imagePath = this.userData.imagePath;

         // this.profileImageURL = this.profileImageURL + this.imagePath;

         if (this.userData.paintingsUpload == 'yes') {
          this.getUserPaintingsData()
          this.created = true;
          
        } else {
          this.created = false;
        }




          console.log('resp data', this.respData.data);
          console.log('user data', this.userData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }


  getUserPaintingsData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserPaintingsData(this.getData).subscribe(
        (resp) => {
          this.userImageDataArray = resp;
          this.userImageData = this.userImageDataArray.data;

          this.userImageDataExists = true;
          console.log('resp data', this.userImageDataArray.data);
          console.log('image data', this.userImageData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }

  getUserVoteData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.selfVoteForPaintings(this.getData).subscribe(
        (resp) => {
          this.userVoteDataArray = resp;
          this.userVoteData = this.userVoteDataArray.data;

          if(this.userVoteData.success == true){
            
            console.log('resp data', this.userVoteDataArray.data);
            console.log('vote data', this.userVoteData);
  
            this.paintingImagePath = this.paintingImagePath+this.userVoteData.imagePath;
            this.uName = this.userVoteData.userName;

            this.userVoteDataExists = true;
  
            console.log('image', this.paintingImagePath);
            console.log('participantId', this.uName);

          }else{
            this.userVoteDataExists = false;
          }

          
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }


  uploadFile(file: any) {
    console.log('image -- ', file);
    const formData = new FormData();
    formData.append('mobileNumber', this.userPhone);
    formData.append('file', file, 'jpg');
    file.inProgress = true;
    console.log('form data', formData);
    this.api.paintingsUpload(formData).pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          //this.startLoader = false;
          this.clicked = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          console.log(event.body.data);
          this.imagePath = event.body.imagePath;

          console.log(this.imagePath);
          //alert('Image successfully uploaded');

          this.savePaintingsData();
        }
      });
  }

  fileChangeEvent(event: any): void {
    console.log('event - ', event)
    this.imageChangedEvent = event;
    this.imageUploaded = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    // console.log(this.croppedImage)
  }
  imageLoaded() {
    // console.log('value', this.croppedImage)
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  uploadImage() {
    if (this.croppedImage == '') {
      alert('Please upload Selfie');
    } else {
      this.clicked = true;
     // this.startLoader = true;
      const file = this.DataURIToBlob(this.croppedImage);
      console.log('file', file);
      this.uploadFile(file);
    }
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }


  savePaintingsData() {
    this.submitted = true;

  
    if (this.imagePath !== null) {
      this.imageData = this.imgForm.value;
      this.imageData['userId'] = this.userId;
      this.imageData['userName'] = this.userName;
      this.imageData['token'] = this.token;
      this.imageData['imagePath'] = this.imagePath;
      this.imageData['consent'] = this.npReq;

      this.api.savePaintingsData(this.imageData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            alert('Paintings has been uploaded successfully!');
            this.refresh();
            //this.router.navigateByUrl('/user-dashboard');
          } else {
           
            alert(this.respData.message);

          
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
         // this.startLoader = false;
          console.log(err);
          this.imgStatus = false;
        }
      );
    } else {
      //this.startLoader = false;
      alert('Please upload Selfie');
      this.imgStatus = false;
    }
  }


  chooseCollection(event: any) {
    this.selectedCollection  = event.target.value;
    alert(this.selectedCollection)
  }


  onDoorstepChange(value) {
    console.log(' Value is : ', value);

    if (value == 'no') {
      this.isDoorstepNo = true;
    } else {
      this.isDoorstepNo = false;
    }
  }

  onChangeState(event, cat: any) {
    if (event.target.checked) {
      this.npReq = cat;
      //alert(cat);
    } else {
      this.npReq = 'No';
      //alert(this.npReq);
    }

    // this.tempArr.brands.push(cat);
  }
 

  submitPaintingForContest(title:any, createdBy:any, about:any, img: any){
    this.imagePath = img;
    var title = title;
    var createdBy = createdBy;
    var about = about;
      if (this.imagePath !== null) {
        this.imageData['userName'] = this.userName;
        this.imageData['userId'] = this.userId;
        this.imageData['token'] = this.token;
        this.imageData['paintingsTitle'] = title;
        this.imageData['createdBy'] = createdBy;
        this.imageData['aboutPaintings'] = about;
        this.imageData['imagePath'] = this.imagePath;
      
        
        this.api.submitPaintingForContest(this.imageData).subscribe(
          (resp) => {
            this.respData = resp;
            if (this.respData.success == true) {
              alert('Your selfie with bappa for contest has been submitted successfully!');
             
             
            } else {
            
              alert(this.respData.message);
  
            
              console.log('error' + this.respData.message);
            }
          },
          (err) => {
           // this.startLoader = false;
            console.log(err);
           
          }
        );
      } else {
        //this.startLoader = false;
        alert('Please create bappa');
       
      }
    
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('bappaCreated');
    
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }

}

