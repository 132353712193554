import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private dialog:MatDialog) { }

  ngOnInit(): void {
  }

  
  privacyView(){
    const dialogRef = this.dialog.open(PrivacyView, {
      autoFocus: false,
      width: '100vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      backdropClass: 'dialog-bg-trans'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.warn(result)
    });
  }

  termsView(): void {
    const dialogRef = this.dialog.open(TermsView, {
      autoFocus: false,
      width: '100vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      backdropClass: 'dialog-bg-trans'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.warn(result)
    });
  }

}

@Component({
  selector: 'termsview',
  templateUrl: 'terms.html',
})
export class TermsView implements OnInit {

  constructor( public dialogRef: MatDialogRef<TermsView>, @Inject(MAT_DIALOG_DATA) public data: any) {
   
   }

  ngOnInit(): void {
   
  
  }

  close() {
   this.dialogRef.close('res')
  } 
}

@Component({
  selector: 'privacyview',
  templateUrl: 'privacy.html',
})
export class PrivacyView implements OnInit {

  constructor( public dialogRef: MatDialogRef<PrivacyView>, @Inject(MAT_DIALOG_DATA) public data: any) {
   
   }

  ngOnInit(): void {
   
  
  }

  close() {
   this.dialogRef.close('res')
  } 
}
