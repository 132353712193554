import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule, } from 'ngx-sharebuttons/icons';

import { UserDashboardRoutingModule } from './user-dashboard-routing.module';
import { UserDashboardComponent } from './user-dashboard.component';
import { CreateBappaComponent } from './create-bappa/create-bappa.component';
import { EditBappaComponent } from './edit-bappa/edit-bappa.component';
import { BappaBackgroundComponent } from './inc/bappa-background/bappa-background.component';
import { BappaIdolComponent } from './inc/bappa-idol/bappa-idol.component';
import { BappaGarlandComponent } from './inc/bappa-garland/bappa-garland.component';
import { BappaFlowerComponent } from './inc/bappa-flower/bappa-flower.component';
import { BappaDecorComponent } from './inc/bappa-decor/bappa-decor.component';
import { BappaSweetsComponent } from './inc/bappa-sweets/bappa-sweets.component';
import { BappaFruitsComponent } from './inc/bappa-fruits/bappa-fruits.component';
import { SelfieComponent } from './selfie/selfie.component';
import { PaintingsComponent } from './paintings/paintings.component';
import { SidebarComponent } from './inc/sidebar/sidebar.component';
import { BappaComponent } from './bappa/bappa.component';
import { PandalComponent } from './pandal/pandal.component';
import { environment } from 'src/environments/environment';

// import { MatButtonModule } from '@angular/material/button';
// import {MatIconModule} from '@angular/material/icon';
// import {MatCardModule} from '@angular/material/card';
// import {MatGridListModule} from '@angular/material/grid-list';
// import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';



@NgModule({
  declarations: [
    UserDashboardComponent,
    CreateBappaComponent,
    EditBappaComponent,
    BappaBackgroundComponent,
    BappaIdolComponent,
    BappaGarlandComponent,
    BappaFlowerComponent,
    BappaDecorComponent,
    BappaSweetsComponent,
    BappaFruitsComponent,
    SelfieComponent,
    PaintingsComponent,
    SidebarComponent,
    BappaComponent,
    PandalComponent
  ],
  imports: [
    CommonModule,
    UserDashboardRoutingModule,
    SlickCarouselModule,
    MaterialModule,
    FormsModule, 
    ReactiveFormsModule,
    ImageCropperModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
  ]
})
export class UserDashboardModule {
  environment=environment
 }
