<div class="d-flex" id="wrapper">

  <!-- Sidebar -->
  <div class="side-navbar border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">

      <div class="dashboard-logo">
        <a routerLink="/home">
          <img src="assets/img/times-bappa-logo.png" loading="lazy">
        </a>
      </div>
    </div>

    <div class="list-group list-group-flush">
        <a routerLink="/user-dashboard" class="list-group-item list-group-item-action">Dashboard</a>
        <a routerLink="/create-bappa" class="list-group-item list-group-item-action" *ngIf="!created">Create Bappa</a>
        <a routerLink="/edit-bappa" class="list-group-item list-group-item-action" *ngIf="created">Edit Bappa</a>
        <a routerLink="/bappa" class="list-group-item list-group-item-action">Bappa Contest</a>
        <a routerLink="/selfie" class="list-group-item list-group-item-action">Selfie With Bappa Contest</a>
        <a routerLink="/paintings" class="list-group-item list-group-item-action">Paintings & Drawings Contest</a>
        <a routerLink="/pandal" class="list-group-item list-group-item-action">Pandal Contest</a>
        <a href="https://theartofindia.in/collections/ganeshotsav" target="_blank" class="list-group-item list-group-item-action">Art Gallery</a>
        <a routerLink="/live-corner" class="list-group-item list-group-item-action">Live Corner</a>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="dashboard-navbar">
      <a class="btn" id="menu-toggle"><i class="bx bx-menu"></i></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dashboard-navbar justify-content-end text-right">
        <button style="background: #7f736d;" mat-mini-fab aria-label="Logout" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>

      </div>
    </nav>
    <main>

      <!-- ======= Breadcrumbs Section ======= -->
      <section class="breadcrumbs" style="margin-top:0 !important">
        <div class="container-fluid">

          <div class="d-flex justify-content-between">
            <h2>Welcome {{userName}}</h2>
            <ol>
              <li><a routerLink="/home">Home</a></li>
              <li>{{title}}</li>
            </ol>
          </div>

        </div>
      </section><!-- End Breadcrumbs Section -->

      <section class="inner-page" style="padding:0 20px !important">
        <div class="container">
          <mat-tab-group dynamicHeight>
            <mat-tab label="View Uploaded Selfies">

              <div class="col-lg-12 col-md-12 col-sm-12 row mt-2" *ngIf="created">
                <div class="col-lg-4 col-md-4 col-sm-4" *ngFor="let x of userImageData">

                  <mat-card class="contest-wal-card">
                    <!-- <mat-card-header>
                              <mat-card-title>Bappa : {{user.imagePath}}</mat-card-title>
                              <mat-card-subtitle>{{user.userName}}</mat-card-subtitle>
                            </mat-card-header> -->
                    <img mat-card-image
                      src='{{"https://s3.ap-south-1.amazonaws.com/www.timesbappa.com-data/selfie/" + x.imagePath}}'  loading="lazy" alt="">
                    <mat-card-content>
                      <p style="height: auto">
                        By : {{x.userName}}
                      </p>
                    </mat-card-content>
                    <mat-card-actions align="justify">
                      <div *ngIf="x.status === 'Approved'">
                        <share-buttons [theme]="'circles-dark'"
                        [include]="['facebook','twitter','linkedin','whatsapp']"
                        [show]="4"
                        [size]="1"
                        [url]="'https://timesbappa.com/selfie-contest/'+x.userId+'/'"
                        [image]="'https://s3.ap-south-1.amazonaws.com/www.timesbappa.com-data/selfie/'+x.imagePath"
                        [autoSetMeta]="false"
                        ></share-buttons>
                      </div>
                      <div class="action-area align-middle">
                        <button mat-button>{{x.status}}</button>
                        <!-- <button mat-button>
                          <a class="btn-dashboard" style="text-decoration: none !important;"  (click)="submitSelfieForContest(x.selfieTitle, x.imagePath)">
                            Submit for Contest
                          </a>
                        </button> -->
                      </div>


                    </mat-card-actions>
                  </mat-card>

                
                </div>

              </div>

              <div class="container" *ngIf="!created">
                <div class="section-title">
                  <h2>Upload<span> Selfie</span></h2>
                  <p>You have not uploaded selfie with bappa! Please upload</p>
                </div>

              </div>
            </mat-tab>

            <mat-tab label="Upload Selfie">
              <div class="row justify-content-center mt-2">
                <div class="card col-md-8">
                  <div class="col-lg-12" data-form-type="formoid">
                    <div style="padding-bottom: 12px; width: 100%">

                      <form [formGroup]="imgForm" class="php-email-form">
                        <div class="row d-flex justify-content-center" style="margin-top: 5%">
                          <div class="row d-flex justify-content-center mt-2">
                            <div class="text-center col-md-6 col-lg-6 col-sm-6 rounded corners">

                              <div class="upload-btn-wrapper" style="margin: auto">
                                <button class="uploadbtn">
                                  <div class="text-center col-md-12 rounded corners"
                                    style="width:100%; max-width: 320px; margin: auto" *ngIf="imageUploaded">
                                    <!-- <p style="padding-top: 10px">Cropped Image Preview</p> -->
                                    <img [src]="croppedImage" style="height: 100%; width: 100%; margin: auto" loading="lazy"/>
                                  </div>
                                  <div style="width:100%; margin: auto" *ngIf="!imageUploaded">
                                    <small>Upload Selfie </small>
                                    <img src="assets/img/selfieicon.png"
                                      style="width:100%; max-width: 320px; margin: auto" loading="lazy" />
                                  </div>
                                </button>
                                <input type="file" name="myfile" accept="image/*" (change)="fileChangeEvent($event)"
                                  style="height: 100%" />
                              </div>

                            </div>
                            <div class="upload-btn-wrapper">
                              <div class="uploadbtn text-center col-md-6 col-lg-6 col-sm-6 rounded corners" style="height:100%; max-width: 220px; margin: auto" *ngIf="imageUploaded">

                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                  [aspectRatio]="4 / 4" [resizeToWidth]="512" format="png"
                                  (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                  (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                              </div>
                            </div>

                          </div>


                          <div class="row justify-content-center mt-2" style="display:none">
                            <div class="col-lg-6 col-md col-sm-6 form-group mt-2" data-for="name">
                              <input id="selfieTitle" name="name" placeholder="Selfie Title" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.selfieTitle.errors }"
                                formControlName="selfieTitle" />
                              <div *ngIf="submitted && f.selfieTitle.errors" class="invalid-feedback">
                                <div *ngIf="f.selfieTitle.errors.required">* Selfie Title is required</div>
                              </div>
                            </div>



                            <div class="col-lg-6 col-md col-sm-6 form-group mt-2" data-for="location">
                              <input id="location" name="location" placeholder="Location" class="form-control"
                                type="text" [ngClass]="{ 'is-invalid': submitted && f.location.errors }"
                                formControlName="location" />
                              <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                                <div *ngIf="f.location.errors.required">* Location is required</div>
                              </div>
                            </div>

                          </div>





                          <div class="row justify-content-center mt-2">
                            <div class="col-1 form-group" data-for="checkbox"
                              style="margin-bottom: 0px; text-align: left; max-width: 4% !important;">
                              <input type="checkbox" value="yes" id="npReq" (change)="onChangeState($event, 'yes')" />
                            </div>

                            <div class="col-11 form-group" data-for="checkbox"
                              style="margin-bottom: 0px; text-align: left; color: black">
                              <label for="npReq">Legal declaration stating image is created by the user and no one else
                                can claim copy right on the same,</label>
                            </div>
                          </div>

                          <div class="row d-flex justify-content-center mt-2">
                            <button mat-button><a class="btn-dashboard" style="text-decoration: none !important;"
                                (click)="uploadImage()">Upload</a></button>
                          </div>


                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </mat-tab>


            <mat-tab label="View Self Voted Selfie">

              <div class="col-lg-12 col-md-12 col-sm-12 row mt-2">
                <div class="col-lg-3 col-md-3 col-sm-3">

                  <mat-card class="contest-wal-card" *ngIf="userVoteDataExists"> 
                    <!-- <mat-card-header>
                          <mat-card-title>Bappa : {{user.imagePath}}</mat-card-title>
                          <mat-card-subtitle>{{user.userName}}</mat-card-subtitle>
                        </mat-card-header> -->
                    <img mat-card-image
                      src='{{selfieImagePath}}'
                      alt="" loading="lazy">
                    <mat-card-content>
                     
                      <p style="height: auto">
                        (By : {{uName}})
                      </p>
                      
                    </mat-card-content>
                    
                  </mat-card>

                  <mat-card *ngIf="!userVoteDataExists">
                    <mat-card-content>
                      <p style="color:rgb(105, 103, 103)">You have not voted yet for selfie contest</p>
                    </mat-card-content>
                  </mat-card>


                </div>

              </div>
            </mat-tab>

          </mat-tab-group>


          

        </div>

        <div class="row d-flex justify-content-center">

          <!-- <div class="col-lg-6 col-md-6">
              <div class="member">
                <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                    style="color:#fff">Upload Selfie</a></button>
              </div>
            </div> -->
          <div class="row mb-3 d-flex justify-content-center text-center">

            <!-- <div class="col-lg-4 col-sm-12">
              <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
                <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                    style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
                <div class="col-12 col-lg-12 sponsor-grid-left">
                  <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                    <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy" />
                  </a>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-8 col-sm-12">

              <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
                <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
                </p>


                <div class="row mb-3 d-flex justify-content-center text-center">
                  <div class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                      <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy"/>
                    </a>

                  </div>

                  <div class="col-4 col-lg-3 sponsor-grid">
                    <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                      <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                    </a>

                  </div>

                  <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" />
                    </a>

                  </div>

                  <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                    </a>

                  </div>





                </div>




              </div>


            </div> -->




          </div>


        </div>

      </section>


    </main>
    <!-- /#page-content-wrapper -->
  </div>