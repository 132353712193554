import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArtGalleryComponent } from './art-gallery/art-gallery.component';
import { BappaContestSingleComponent } from './bappa-contest-single/bappa-contest-single.component';
import { BappaContestComponent } from './bappa-contest/bappa-contest.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LiveCornerComponent } from './live-corner/live-corner.component';
import { LoginComponent } from './login/login.component';
import { PaintingContestSingleComponent } from './painting-contest-single/painting-contest-single.component';
import { PaintingContestComponent } from './painting-contest/painting-contest.component';
import { PandalContestSingleComponent } from './pandal-contest-single/pandal-contest-single.component';
import { PandalContestComponent } from './pandal-contest/pandal-contest.component';
import { PandalRegistrationComponent } from './pandal-registration/pandal-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { SelfieContestSingleComponent } from './selfie-contest-single/selfie-contest-single.component';
import { SelfieContestComponent } from './selfie-contest/selfie-contest.component';
import { WelcomeComponent } from './welcome/welcome.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'forgot-password', component:ForgotPasswordComponent},
  { path: 'pandal-registeration', component: PandalRegistrationComponent },
  { path: 'bappa-contest', component: BappaContestComponent},
  { path: 'bappa-contest/:id/:image', component: BappaContestSingleComponent},
  { path: 'selfie-contest', component:SelfieContestComponent},
  { path: 'selfie-contest/:id', component:SelfieContestSingleComponent},
  { path: 'painting-contest', component:PaintingContestComponent},
  { path: 'painting-contest/:id', component:PaintingContestSingleComponent},
  { path: 'pandal-contest', component: PandalContestComponent},
  { path: 'pandal-contest/:id', component:PandalContestSingleComponent},
  { path: 'art-gallery', component: ArtGalleryComponent},
  { path: 'live-corner', component: LiveCornerComponent},
  { path: 'user-dashboard', loadChildren: () => import('./user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule) },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
