<!-- <div class="outer-grid">
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/1.png" class="js-cd-close" (click)="addNewItem('1.png')"/>
        <img src="assets/img/bappa-element/decor/2.png" class="js-cd-close" (click)="addNewItem('2.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/3.png" class="js-cd-close" (click)="addNewItem('3.png')"/>
        <img src="assets/img/bappa-element/decor/4.png" class="js-cd-close" (click)="addNewItem('4.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/5.png" class="js-cd-close" (click)="addNewItem('5.png')"/>
        <img src="assets/img/bappa-element/decor/6.png" class="js-cd-close" (click)="addNewItem('6.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/7.png" class="js-cd-close" (click)="addNewItem('7.png')"/>
        <img src="assets/img/bappa-element/decor/8.png" class="js-cd-close" (click)="addNewItem('8.png')"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/9.png" class="js-cd-close" (click)="addNewItem('9.png')"/>
        <img src="assets/img/bappa-element/decor/10.png" class="js-cd-close" (click)="addNewItem('10.png')"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/11.png" class="js-cd-close" (click)="addNewItem('11.png')"/>
        <img src="assets/img/bappa-element/decor/12.png" class="js-cd-close" (click)="addNewItem('12.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/13.png" class="js-cd-close" (click)="addNewItem('13.png')"/>
        <img src="assets/img/bappa-element/decor/14.png" class="js-cd-close" (click)="addNewItem('14.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/15.png" class="js-cd-close" (click)="addNewItem('15.png')"/>
        <img src="assets/img/bappa-element/decor/16.png" class="js-cd-close" (click)="addNewItem('16.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/17.png" class="js-cd-close" (click)="addNewItem('17.png')"/>
        <img src="assets/img/bappa-element/decor/18.png" class="js-cd-close" (click)="addNewItem('18.png')"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/19.png" class="js-cd-close" (click)="addNewItem('19.png')"/>
        <img src="assets/img/bappa-element/decor/20.png" class="js-cd-close" (click)="addNewItem('20.png')"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/21.png" class="js-cd-close" (click)="addNewItem('21.png')"/>
        <img src="assets/img/bappa-element/decor/22.png" class="js-cd-close" (click)="addNewItem('22.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/23.png" class="js-cd-close" (click)="addNewItem('23.png')"/>
        <img src="assets/img/bappa-element/decor/24.png" class="js-cd-close" (click)="addNewItem('24.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/25.png" class="js-cd-close" (click)="addNewItem('25.png')"/>
        <img src="assets/img/bappa-element/decor/26.png" class="js-cd-close" (click)="addNewItem('26.png')"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/decor/27.png" class="js-cd-close" (click)="addNewItem('27.png')"/>
       
    </div>

   
    
</div> -->

<mat-grid-list cols="4">
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/1.png" class="js-cd-close" (click)="addNewItem('1.png')" loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/2.png" class="js-cd-close" (click)="addNewItem('2.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/3.png" class="js-cd-close" (click)="addNewItem('3.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/4.png" class="js-cd-close" (click)="addNewItem('4.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/5.png" class="js-cd-close" (click)="addNewItem('5.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/6.png" class="js-cd-close" (click)="addNewItem('6.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/7.png" class="js-cd-close" (click)="addNewItem('7.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/8.png" class="js-cd-close" (click)="addNewItem('8.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/9.png" class="js-cd-close" (click)="addNewItem('9.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/10.png" class="js-cd-close" (click)="addNewItem('10.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/11.png" class="js-cd-close" (click)="addNewItem('11.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/12.png" class="js-cd-close" (click)="addNewItem('12.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/13.png" class="js-cd-close" (click)="addNewItem('13.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/14.png" class="js-cd-close" (click)="addNewItem('14.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/15.png" class="js-cd-close" (click)="addNewItem('15.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/16.png" class="js-cd-close" (click)="addNewItem('16.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/17.png" class="js-cd-close" (click)="addNewItem('17.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/18.png" class="js-cd-close" (click)="addNewItem('18.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/19.png" class="js-cd-close" (click)="addNewItem('19.png')"  loading="lazy"/></mat-grid-tile>
    
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/21.png" class="js-cd-close" (click)="addNewItem('21.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/decor/22.png" class="js-cd-close" (click)="addNewItem('22.png')"  loading="lazy"/></mat-grid-tile>
    
    
    
  </mat-grid-list>