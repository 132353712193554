import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bappa-sweets',
  templateUrl: './bappa-sweets.component.html',
  styleUrls: ['./bappa-sweets.component.css']
})
export class BappaSweetsComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

}
