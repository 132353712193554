import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../api.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bappa-contest',
  templateUrl: './bappa-contest.component.html',
  styleUrls: ['./bappa-contest.component.scss']
})
export class BappaContestComponent implements OnInit {
  title: string = "Bappa Contest";
  respData:any = {};
  userData:any = {};
  newUserData:any = {}
  hidden = false;
  environment = environment;
  shareOnWeb: any;

  userName: string;
  
  loggedIn:boolean = false;
  public userId;
  public token;

  displayName: string = "";

  voterData:any = {};
  nextToken:any;

  notEmptyData = true;
  notscrolly = true;
  
  getData:any = {}
  city:string="";

  searchable:boolean = false;
  inputvalue = ''
  
  changeToSentenceCase(event) {

      this.inputvalue = event.replace(/\b\w/g, 
          event => event.toLocaleUpperCase());

  }
  constructor(private api:ApiService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.city = localStorage.getItem('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }

    this.getAllApprovedBappa()
    
  }


  getAllApprovedBappa() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

      this.api.getAllApprovedBappa().subscribe(
        (resp) => {
          
          this.respData = resp;
          this.nextToken = this.respData.contToken;
          this.userData = this.respData.data;

          console.log(this.userData)
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }



onScroll() {
  if (this.notscrolly && this.notEmptyData) {
    this.notscrolly = false;

    if(this.nextToken !== undefined){
      this.loadNextData();
    }
    
  }
}
  
loadNextData() {
  
  this.getData = { contToken: this.nextToken };



  this.api.getNextApprovedBappa(this.getData).subscribe(
    (resp) => {
      
      this.respData = resp;

      if (this.respData.length === 0 ) {
        this.notEmptyData =  false;
      }
      this.nextToken = this.respData.contToken;
      this.newUserData = this.respData.data;

      this.userData = this.userData.concat(this.newUserData);

      this.userData = this.userData.sort(
        (low:any, high:any) => +high.voteCount - +low.voteCount
      );
      console.log(this.userData)
    },
    (err) => {
      
      console.log(err);
    }
  );
  
}

searchUserBappa(data:any){
  this.getData = { searchTerm: data };

  this.api.searchUserBappa(this.getData).subscribe(
    (resp) => {
      
      this.respData = resp;
      this.userData = this.respData.data;
      this.searchable = true;


    },
    (err) => {
      
      console.log(err);
    }
  );
}


toggleBadgeVisibility() {
  this.hidden = !this.hidden;
}



voteNow(id:any, name:string, img:any){
  console.log(this.loggedIn)
  if(this.loggedIn === true){
    this.voterData['voterId'] = this.userId;
    this.voterData['token'] = this.token;
    this.voterData['userId'] = id;
    this.voterData['userName'] = name;
    this.voterData['imagePath'] = img;

    this.api.voteForBappa(this.voterData).subscribe(
      (resp) => {
        //alert('working');
        this.respData = resp;
        console.log('resp ---', resp)
        if (this.respData.success == true) {
          alert(this.respData.message);
          this.refresh();
        } else {
          // this.startLoader = false;
          alert(this.respData.message);
          console.log('error' + this.respData.message);
        }
      },
      (err) => {
        //alert('error');
        // this.startLoader = false;
        console.log(err);
      }
    );
    
  }else{
    alert("plaese login for voting");
    this.router.navigate(['/login', { landingPage:"/bappa-contest", id: id, imagePath:img }]);
  } 
}

refresh(): void {
  window.location.reload();
}

share(event, story) {
  event.preventDefault();
  event.stopPropagation();
  story.shareOnWeb = true;
}

closeShareOptions() {
  this.userData.map(element => element.shareOnWeb = false);
}
}
