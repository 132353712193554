
    <main id="main" class="container-fluid parallax-welcome">
		<div class="site-logo">
			<img src="assets/img/times-bappa-logo.png" loading="lazy">
		</div>

		<div class="row text-center">		
			
				<div class="col-lg-8 col-md-8 left-side">	

							
					
							<div class="row">
								<div class="col-lg-12 title-area">	
								<h2 class="display-6" style="color:#ffffff" id="welcome">Welcome to Times Bappa!<br>
								<span style="font-size:16px !important">An initiative of</span> <span style="color:#ffd900; font-family:'Times New Roman', Times, serif;">THE TIMES OF INDIA</span></h2>
								<h2 class="display-6" style="color:#ffd900" id="iniative">An initiative of <br><span style="color:#ffffff">THE TIMES OF INDIA</span></h2>
					   
						
							 </div>
							<!-- <img src="assets/img/times-bappa-logo.png">			 -->
						</div>
				</div>

				<div class="col-lg-4 col-md-4">
					<div class="col-lg-12 right-side">

						<h4 class="btn-dashboard" style="color:#ffffff; font-weight: bold;">Select your city</h4>

						<div class="row d-flex justify-content-center text-center" style="margin-top: 10%;">
                        
						
								<div class="col-lg-12 city-icon shadow-sm mb-4 rounded" (click)="goToHome('Mumbai')">
            
									<div class="view overlay">
									  <img class="zoom pre-zoom" src='assets/img/cityicon/mumbai.jpg' loading="lazy">
									</div>
								  
								</div>
							
						</div>
	
						<div class="row d-flex justify-content-center text-center" >
							
							
								<div class="col-lg-12 city-icon shadow-sm mb-4 rounded" (click)="goToHome('Pune')">
            
									<div class="view overlay">
									  <img class="zoom pre-zoom" src='assets/img/cityicon/pune.jpg' loading="lazy">
									</div>
								  
								  </div>
							
						</div>
	
						<div class="row d-flex justify-content-center text-center">
							
							
								<div class="col-lg-12 city-icon shadow-sm mb-4 rounded" (click)="goToHome('Nagpur')">
            
									<div class="view overlay">
									  <img class="zoom pre-zoom" src='assets/img/cityicon/nagpur.jpg' loading="lazy">
									</div>
								  
								  </div>
							
						</div>

					</div>
					
							
								
	
				</div>
			
		</div>

	

		

    </main>
	
		<app-footer></app-footer>
	
	

