<!-- <div class="outer-grid">
    <div class="inner-grid">
        <img src="assets/img/bappa-element/garland/1.png" class="js-cd-close" (click)="addNewItem('1.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/garland/2.png" class="js-cd-close" (click)="addNewItem('2.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/garland/3.png" class="js-cd-close" (click)="addNewItem('3.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/garland/4.png" class="js-cd-close" (click)="addNewItem('4.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/garland/5.png" class="js-cd-close" (click)="addNewItem('5.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/garland/6.png" class="js-cd-close" (click)="addNewItem('6.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/garland/7.png" class="js-cd-close" (click)="addNewItem('7.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/garland/8.png" class="js-cd-close" (click)="addNewItem('8.png')"  loading="lazy"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/garland/9.png" class="js-cd-close" (click)="addNewItem('9.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/garland/10.png" class="js-cd-close" (click)="addNewItem('10.png')"  loading="lazy"/>
    </div>
   
    
</div> -->

<mat-grid-list cols="4">
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/1.png" class="js-cd-close" (click)="addNewItem('1.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/2.png" class="js-cd-close" (click)="addNewItem('2.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/3.png" class="js-cd-close" (click)="addNewItem('3.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/4.png" class="js-cd-close" (click)="addNewItem('4.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/5.png" class="js-cd-close" (click)="addNewItem('5.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/6.png" class="js-cd-close" (click)="addNewItem('6.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/7.png" class="js-cd-close" (click)="addNewItem('7.png')"  loading="lazy"/></mat-grid-tile>
    
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/9.png" class="js-cd-close" (click)="addNewItem('9.png')"  loading="lazy"/></mat-grid-tile>
    
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/11.png" class="js-cd-close" (click)="addNewItem('11.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/12.png" class="js-cd-close" (click)="addNewItem('12.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/13.png" class="js-cd-close" (click)="addNewItem('13.png')"  loading="lazy"/></mat-grid-tile>
    
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/15.png" class="js-cd-close" (click)="addNewItem('15.png')"  loading="lazy"/></mat-grid-tile>
   
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/17.png" class="js-cd-close" (click)="addNewItem('17.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/18.png" class="js-cd-close" (click)="addNewItem('18.png')"  loading="lazy"/></mat-grid-tile>
   
    <mat-grid-tile class="mat-grid">  <img src="assets/img/bappa-element/garland/20.png" class="js-cd-close" (click)="addNewItem('20.png')"  loading="lazy"/></mat-grid-tile>
  </mat-grid-list>