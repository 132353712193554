<div mat-dialog-content class="card shadow-sm bg-white rounded" style="width:100%; height:100%;">
<button type="button" (click)="close()" class="close dialog-close-btn" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>


<div class="card-header card-header-danger" style="background: #6b6867; color: #fff">
    <h4 class="card-title">TERMS AND CONDITIONS:</h4>
    
</div>
 <div class="card-body">
  
             
    <ol>
        <li>Only entries complete in all respects will be considered.</li>
        <li>No entry fee is required. </li>
        <li>Employees of Bennett Coleman & Co. Ltd, sponsors of the event, associated agencies and their immediate families and relatives cannot participate in the competition. </li>
        <li>Participation is open only to the residents of Mumbai, Delhi, Bangalore, Pune, Chennai, Hyderabad, Kolkata & Ahmedabad cities. </li>
        <li>Winner is solely responsible for any other expenses related to the collection, acceptance and use of the prizes. </li>
        <li>BCCL reserves the right to modify or cancel or discontinue or terminate the scheme at any time without giving any prior notice and without assigning any reason whatsoever.</li>
        <li>BCCL shall not be responsible for any loss or damage suffered by the winner while availing the offer.</li>
        <li>All disputes shall be subject exclusively to eligible city Jurisdiction only. </li>
        <li>The prize cannot be exchanged for its value in cash.</li>
        <li>BCCL will not be responsible for any defects / inadequacy in the products given away as prizes and the same would have to be addressed to the manufacturer directly. BCCL would not be liable to replace/exchange the prizes at any stage of the offer. </li>
    </ol>
      
  
</div> 


</div>