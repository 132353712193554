import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loggedIn:boolean = false;
  public userId;
  public token;

  constructor() { }

  ngOnInit(): void {
    this.loadJsMain("assets/js/main.js");  
    $(".mobile-nav-toggle").click(function(e) {
      e.preventDefault();
      var a = $("#navbar").attr('class')
      
      if(a == 'order-last order-lg-0 navbar'){
        $('#navbar').removeClass('navbar')
        $('#navbar').addClass('navbar-mobile')
         this.classList.toggle('bi-list')
         this.classList.toggle('bi-x')
      }

      if(a == 'order-last order-lg-0 navbar-mobile'){
        $('#navbar').removeClass('navbar-mobile')
        $('#navbar').addClass('navbar')
         this.classList.toggle('bi-list')
         this.classList.toggle('bi-x')
      }
      
     
    });

    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }

  }

  loadJsMain(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('head')[0].appendChild(node);  
  } 

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('bappaCreated');
    
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }

}
