<!-- <div class="outer-grid">
    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/1.jpg" class="js-cd-close" (click)="addNewItem('1.jpg')"/>  
    </div>

    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/2.jpg" class="js-cd-close" (click)="addNewItem('2.jpg')"/> 
    </div>

    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/3.jpg" class="js-cd-close" (click)="addNewItem('3.jpg')"/>  
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/background/4.jpg" class="js-cd-close" (click)="addNewItem('4.jpg')"/>
    </div>
    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/5.jpg" class="js-cd-close" (click)="addNewItem('5.jpg')"/>  
    </div>

    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/6.jpg" class="js-cd-close" (click)="addNewItem('6.jpg')"/> 
    </div>

    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/7.jpg" class="js-cd-close" (click)="addNewItem('7.jpg')"/>  
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/background/8.jpg" class="js-cd-close" (click)="addNewItem('8.jpg')"/>
    </div>
    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/9.jpeg" class="js-cd-close" (click)="addNewItem('9.jpg')"/>  
    </div>

    <div class="inner-grid"> 
        <img src="assets/img/bappa-element/background/10.jpg" class="js-cd-close" (click)="addNewItem('10.jpg')"/> 
    </div>

     
</div> -->

<!-- <div class="row d-flext justify-content-center text-center">
    <h4>Powered by : </h4>
    <img src="assets/img/sponsor-logo/asian-paints-logo.png" class="mat-grid mb-3" style="width:180px;">
  </div> -->

<mat-grid-list cols="4">
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/1.jpeg" class="js-cd-close" (click)="addNewItem('1.jpeg')" loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/2.jpeg" class="js-cd-close" (click)="addNewItem('2.jpeg')" loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/3.jpg" class="js-cd-close" (click)="addNewItem('3.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/4.jpg" class="js-cd-close" (click)="addNewItem('4.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/5.jpg" class="js-cd-close" (click)="addNewItem('5.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/6.jpg" class="js-cd-close" (click)="addNewItem('6.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/7.jpg" class="js-cd-close" (click)="addNewItem('7.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/8.jpg" class="js-cd-close" (click)="addNewItem('8.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/9.jpg" class="js-cd-close" (click)="addNewItem('9.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/10.jpg" class="js-cd-close" (click)="addNewItem('10.jpg')"  loading="lazy"/></mat-grid-tile>

    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/11.jpg" class="js-cd-close" (click)="addNewItem('11.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/12.jpg" class="js-cd-close" (click)="addNewItem('12.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/13.jpg" class="js-cd-close" (click)="addNewItem('13.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/14.jpg" class="js-cd-close" (click)="addNewItem('14.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/15.jpg" class="js-cd-close" (click)="addNewItem('15.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/16.jpg" class="js-cd-close" (click)="addNewItem('16.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/17.jpg" class="js-cd-close" (click)="addNewItem('17.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/18.jpg" class="js-cd-close" (click)="addNewItem('18.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/19.jpg" class="js-cd-close" (click)="addNewItem('19.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/20.jpg" class="js-cd-close" (click)="addNewItem('20.jpg')"  loading="lazy"/></mat-grid-tile>

    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/21.jpg" class="js-cd-close" (click)="addNewItem('21.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/22.jpg" class="js-cd-close" (click)="addNewItem('22.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/23.jpg" class="js-cd-close" (click)="addNewItem('23.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/24.jpg" class="js-cd-close" (click)="addNewItem('24.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/25.jpg" class="js-cd-close" (click)="addNewItem('25.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/26.jpg" class="js-cd-close" (click)="addNewItem('26.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/27.jpg" class="js-cd-close" (click)="addNewItem('27.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/28.jpg" class="js-cd-close" (click)="addNewItem('28.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/29.jpg" class="js-cd-close" (click)="addNewItem('29.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/30.jpg" class="js-cd-close" (click)="addNewItem('30.jpg')"  loading="lazy"/></mat-grid-tile>

    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/31.jpg" class="js-cd-close" (click)="addNewItem('31.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/32.jpg" class="js-cd-close" (click)="addNewItem('32.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/33.jpg" class="js-cd-close" (click)="addNewItem('33.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/34.jpg" class="js-cd-close" (click)="addNewItem('34.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/35.jpg" class="js-cd-close" (click)="addNewItem('35.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/36.jpg" class="js-cd-close" (click)="addNewItem('36.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/37.jpg" class="js-cd-close" (click)="addNewItem('37.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/38.jpg" class="js-cd-close" (click)="addNewItem('38.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/39.jpg" class="js-cd-close" (click)="addNewItem('39.jpg')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid"> <img src="assets/img/bappa-element/background/40.jpg" class="js-cd-close" (click)="addNewItem('40.jpg')"  loading="lazy"/></mat-grid-tile>
  </mat-grid-list>