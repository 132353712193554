import {
  Component,
  OnInit,
} from '@angular/core';
import { Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  title: string = "Forgot Password"
  forgotForm: FormGroup;

  submitted = false;

  sentotp: boolean = false;
  verifyotp: boolean = false;
  verified: boolean = true;

  v: boolean = false;

  messageOtp: String = '';
  messageMobile: String = '';
  sendButton: String = 'Send OTP';
  isSubmit: boolean = false;

  recaptchaStr = '';
  respData: any = {};

  mobile: any = '';
  otpdata: any = {};
  sendMobileNo: any = {};
  userData: any;
  city:string="";
  userInfo = { mobileNumber: '', mobileNumber1: '', otp: '' };

  resp: any;
  constructor(private api:ApiService, private fb: FormBuilder, private router:Router) { }

  ngOnInit(): void {
    this.city = localStorage.getItem('city');
    this.forgotForm = this.fb.group({
      userPhone: [
        '',
        [Validators.required, Validators.pattern('^((\\-?))?[0-9]{10}$')],
      ],
      otp: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
  get f() {
    return this.forgotForm.controls;
  }
  
  changePassword() {
  
    this.submitted = true;
  
    // if (this.forgotForm.invalid) {
    //   //alert('form validation');
    //   alert('Please fill all details');
    //   this.v = true;
    //  // this.startLoader = false;
    //   return;
    // }
  
    if (this.verified == false) {
      // alert("2")
      this.userData = this.forgotForm.value;
      this.userData['phone'] = this.mobile;
  
      console.log(this.userData)
      this.api.forgotpassword(this.userData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
           alert('you have successfully change your password! please login with new password')
            this.router.navigateByUrl('/login');
          } else {
           //// this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //this.startLoader = false;
          console.log(err);
        }
      );
    } else {
      //this.startLoader = false;
      this.v = true;
      alert('Please verify mobile number.');
    }
  }
  
  
  
  
  sendOtp() {
  
    this.mobile = this.forgotForm.get('userPhone').value;
    this.sendMobileNo['token'] = this.recaptchaStr;
    this.sendMobileNo['phone'] = this.mobile;
  
    if (this.forgotForm.get('userPhone').invalid) {
      alert('Please enter 10 digit valid Mobile Number');
    } else {
     // this.startLoaderOTP = true;
      this.api.sendOtp(this.sendMobileNo).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.sentotp = true;
            //this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
            this.sendButton = 'Resend';
          } else {
            this.sentotp = false;
           // this.startLoaderOTP = false;
            this.messageMobile = this.respData.message;
          }
        },
        (err) => {
         // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
   
  }
  
  verifyOtp(data: any) {
    this.otpdata['phone'] = this.forgotForm.get('userPhone').value;
    this.otpdata['otp'] = data;
  
    if (this.otpdata['phone'] !== '' && this.otpdata['otp'] !== '') {
      this.api.verifyOtp(this.otpdata).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
            this.sentotp = false;
            this.sendButton = 'Verified';
            this.isSubmit = true;
  
            this.forgotForm.get('userPhone').disable();
            this.verified = false;
            this.v = false;
          } else {
            this.verifyotp = true;
            this.messageOtp = this.respData.message;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      alert('Please enter Valid OTP');
    }
  }
  
  
    onOtpClick(captchaRef: any): void {
      if (this.recaptchaStr) {
        captchaRef.reset();
      }
      captchaRef.execute();
    }
  
    public resolved(captchaResponse: string): void {
      this.recaptchaStr = captchaResponse;
      if (this.recaptchaStr) {
        this.sendOtp();
      }
    }
  
    hideOTP() {
      this.sentotp = false;
    }
}
