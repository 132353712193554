import { Component, OnInit, Inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {ApiService} from '../api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-art-gallery',
  templateUrl: './art-gallery.component.html',
  styleUrls: ['./art-gallery.component.css']
})
export class ArtGalleryComponent implements OnInit {

  title: string = "Art Gallery";
  respData: any = {};
  userData: any = {};
  hidden = false;

  userName: string;
  userId: string;

  displayName: string = "";

  dataFilter: any = { "artist": ''};

  pageIndex:number = 0;
  pageSize:number = 12;
  lowValue:number = 0;
  highValue:number = 12;    

  items = [];
  pageOfItems: Array<any>;


  constructor(private api:ApiService, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getArtGalleryData()
    
    
  }


  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.scrollTop()
    return event;
  }

  scrollTop(){
    window.scroll(0,0);
  }

  

  getArtGalleryData() {
    // if (this.token != null && this.userId != null) {
    //   this.getData = { userId: this.userId, token: this.token };

      this.api.getArtGalleryData().subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          console.log(this.userData)




        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

   refresh(): void {
     window.location.reload();
   }



   viewItem(imgPath:any, name:string, artist:string, url:string): void {
    const dialogRef = this.dialog.open(ItemView, {
      autoFocus: false,
      width: '100vw',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {imgPath: imgPath, name:name, artist: artist, url:url},
      backdropClass: 'dialog-bg-trans'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.warn(result)
    });
  }
}


@Component({
  selector: 'itemview',
  templateUrl: 'item-view.html',
})
export class ItemView implements OnInit {
 
 
  imgPath:any;
  name:string;
  artist:string;
  url:string;

  zoomProperties = {
    "double-tap": true, // double tap to zoom in and out.
    "overflow": "hidden",// Am not sure. But Documentation says, it will not render elements outside the container.
    "wheel": false, // Disables mouse - To enable scrolling. Else mouse scrolling will be used to zoom in and out on web.
    "disableZoomControl": "disable", // stops showing zoom + and zoom - images.
    "backgroundColor": "rgba(0,0,0,0)" // Makes the pinch zoom container color to transparent. So that ionic themes can be applied without issues.
   }

  constructor( public dialogRef: MatDialogRef<ItemView>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imgPath = data.imgPath;
    this.name = data.name;
    this.artist = data.artist;
    this.url = data.url
   
   }

  ngOnInit(): void {
   
  
  }

  

  close() {
   this.dialogRef.close('res')
  }


  
}
