import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaServiceService {

  promptEvent:any;
  constructor(private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      window.addEventListener('beforeinstallprompt', event => {
        this.promptEvent = event;
      });
    });
  }
}
