<app-inner-header [title]="title"></app-inner-header>
<main id="main">

    <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-end align-items-right">
        <!-- <h2>Registration</h2> -->
        <ol>
          <li><a routerLink="/">Home</a></li>
          <li>{{title}}</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->

    <section class="inner-page parallax" style="padding:10px !important;">
      <div class="container" style="position: relative;">
        <!-- ======= Login Section ======= -->


        <section id="book-a-table" class="book-a-table" style="padding:10px !important;">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">

              <div class="col-lg-6 col-lg-6 col-sm-12">


                <div class="section-title">
                  <h2>User <span>Login</span></h2>
                 
                </div>

                <form  [formGroup]="loginForm" class="php-email-form">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 form-group">
                      <input id="loginPhone" name="loginPhone" placeholder="Mobile Number" class="form-control" [ngClass]="{'is-invalid': submittedLogin && lf.loginPhone.errors}" formControlName="loginPhone"/>
                      <div *ngIf="submittedLogin && lf.loginPhone.errors" class="invalid-feedback">
                          <div *ngIf="lf.loginPhone.errors.required">
                              * Mobile Number is required
                          </div>
                          <div *ngIf="lf.loginPhone.errors.pattern">
                              * Please, Enter 10 digit Mobile Number
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 form-group mt-3 mt-md-0">
                      <input type="password" id="loginPassword" name="loginPassword" placeholder="Password" class="form-control" [ngClass]="{'is-invalid': submittedLogin && lf.loginPassword.errors}" formControlName="loginPassword"/>
                      <div *ngIf="submittedLogin && lf.loginPassword.errors" class="invalid-feedback">
                          <div *ngIf="lf.loginPassword.errors.required">
                              * Password is required
                          </div>
                         
                      </div>
                    </div>


                    <div class="text-center mt-1 mb-1"><button type="submit"><a (click)="login()" style="color:#fff !important; text-decoration: none !important;">Submit</a></button></div>
                    <div class="text-center mt-1 mb-1"> 
                      <p><strong><a class="nav-link scrollto" (click)="gotoRegister()" style="cursor: pointer;">New User! Register Here</a></strong></p>
                    </div>
                    <div class="text-center mb-2">
                      <a style="cursor: pointer;" routerLink="/forgot-password">Forgot Password</a>
                    </div>
                </div>
                </form>
                

              </div>
            </div>

            
          
           
          

          </div>
        </section><!-- End Login Section -->

        <div class="row d-flex justify-content-center">

          <!-- <div class="col-lg-6 col-md-6">
              <div class="member">
                <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                    style="color:#fff">Upload Selfie</a></button>
              </div>
            </div> -->
          <!-- <div class="row mb-3 d-flex justify-content-center text-center" *ngIf="city != 'Mumbai' ">

            <div class="col-lg-4 col-sm-12">
              <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
                <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                    style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
                <div class="col-12 col-lg-12 sponsor-grid-left">
                  <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                    <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy"/>
                  </a>

                </div>
              </div>

            </div>



            <div class="col-lg-8 col-sm-12">

              <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
                <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
                </p>


                <div class="row mb-3 d-flex justify-content-center text-center">
                  <div class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                      <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy" />
                    </a>

                  </div>

                  <div class="col-4 col-lg-3 sponsor-grid">
                    <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                      <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                    </a>

                  </div>

                  <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                    </a>

                  </div>

                  <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                    <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                      <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                    </a>

                  </div>





                </div>




              </div>


            </div>




          </div> -->


        </div>
      </div>
    </section>

  </main><!-- End #main -->

<app-footer></app-footer>