import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-live-corner',
  templateUrl: './live-corner.component.html',
  styleUrls: ['./live-corner.component.css']
})
export class LiveCornerComponent implements OnInit {
  title:string="Live Corner";

  respData: any = {};
  performancesData: any = {};
  workshopsData: any = {};
  aartiData: any = {};
  fbVideosData:any = {};
  city:String="";
  getData = {};


  safeSrcPerformances: SafeResourceUrl[] = [];
  safeSrcWorkshops: SafeResourceUrl[] = [];
  safeSrcAarti: SafeResourceUrl[] = [];
  safeSrcFbVideos: SafeResourceUrl[] = [];



  constructor(private api:ApiService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.city =localStorage.getItem('city')
    // this.getLiveCornerPerformanceData();
    // this.getLiveCornerWorkshopData();
    this.getLiveCornerAartiData();
    // this.getFbLiveCornerData();
  }


  // getLiveCornerPerformanceData() {
  //   // if (this.token != null && this.userId != null) {
  //   this.getData = {category: "performances"};

  //     this.api.getLiveCornerData(this.getData).subscribe(
  //       (resp) => {
  //         this.respData = resp;
  //         this.performancesData = this.respData.data;

  //         this.performancesData.forEach(value=> {
  //           console.log(value.videoUrl);
  //           this.safeSrcPerformances.push(this.sanitizer.bypassSecurityTrustResourceUrl(value.videoUrl))
  //        });
         

  //         console.log(this.performancesData)
  //       },
  //       (err) => {
  //         // this.startLoaderOTP = false;
  //         console.log(err);
  //       }
  //     );
  // }

  // getLiveCornerWorkshopData() {
  //   // if (this.token != null && this.userId != null) {
  //     this.getData = { category: "workshops" };

  //     this.api.getLiveCornerData(this.getData).subscribe(
  //       (resp) => {
  //         this.respData = resp;
  //         this.workshopsData = this.respData.data;

  //         this.workshopsData.forEach(value=> {
  //           console.log(value.videoUrl);
  //           this.safeSrcWorkshops.push(this.sanitizer.bypassSecurityTrustResourceUrl(value.videoUrl))
  //        });
         

  //         console.log(this.workshopsData)
  //       },
  //       (err) => {
  //         // this.startLoaderOTP = false;
  //         console.log(err);
  //       }
  //     );
  // }

  getLiveCornerAartiData() {
    // if (this.token != null && this.userId != null) {
      this.getData = { category: "aarti" };

      this.api.getLiveCornerData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          console.log(this.respData)
          this.aartiData = this.respData.data;

          console.log('this.aartiData', this.aartiData)

          this.aartiData.forEach(value=> {
            console.log(value.videoUrl);
            this.safeSrcAarti.push(this.sanitizer.bypassSecurityTrustResourceUrl(value.videoUrl))
         });

          console.log(this.aartiData)
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }

  // getFbLiveCornerData() {
  //   // if (this.token != null && this.userId != null) {
  //     this.getData = { category: "performances" };

  //     this.api.getFbLiveCornerData(this.getData).subscribe(
  //       (resp) => {
  //         this.respData = resp;
  //         this.fbVideosData = this.respData.data;
          
  //         if(this.fbVideosData != undefined){
  //           this.fbVideosData.forEach(value=> {
  //             console.log(value.fbVideoUrl);
  //             this.safeSrcFbVideos.push(this.sanitizer.bypassSecurityTrustResourceUrl(value.videoUrl))
  //          });
  
  //           console.log(this.fbVideosData)
  //         }
          
  //       },
  //       (err) => {
  //         // this.startLoaderOTP = false;
  //         console.log(err);
  //       }
  //     );
  // }

}
