<!-- <div class="outer-grid">
    <div class="inner-grid">
        <img src="assets/img/bappa-element/sweets/1.png" class="js-cd-close" (click)="addNewItem('1.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/sweets/2.png" class="js-cd-close"(click)="addNewItem('2.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/sweets/3.png" class="js-cd-close"(click)="addNewItem('3.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/sweets/4.png" class="js-cd-close" (click)="addNewItem('4.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/sweets/5.png" class="js-cd-close" (click)="addNewItem('5.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/sweets/6.png" class="js-cd-close" (click)="addNewItem('6.png')"  loading="lazy"/>
    </div>
    <div class="inner-grid">
        <img src="assets/img/bappa-element/sweets/7.png" class="js-cd-close" (click)="addNewItem('7.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/sweets/8.png" class="js-cd-close" (click)="addNewItem('8.png')"  loading="lazy"/>
    </div>

    <div class="inner-grid">
        <img src="assets/img/bappa-element/sweets/9.png" class="js-cd-close" (click)="addNewItem('9.png')"  loading="lazy"/>
        <img src="assets/img/bappa-element/sweets/10.png" class="js-cd-close" (click)="addNewItem('10.png')"  loading="lazy"/>
    </div>
   
    
</div> -->

<mat-grid-list cols="4">
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/1.png" class="js-cd-close" (click)="addNewItem('1.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/2.png" class="js-cd-close" (click)="addNewItem('2.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/3.png" class="js-cd-close" (click)="addNewItem('3.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/4.png" class="js-cd-close" (click)="addNewItem('4.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/5.png" class="js-cd-close" (click)="addNewItem('5.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/6.png" class="js-cd-close" (click)="addNewItem('6.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/7.png" class="js-cd-close" (click)="addNewItem('7.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/8.png" class="js-cd-close" (click)="addNewItem('8.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/9.png" class="js-cd-close" (click)="addNewItem('9.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/10.png" class="js-cd-close" (click)="addNewItem('10.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/11.png" class="js-cd-close" (click)="addNewItem('11.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/12.png" class="js-cd-close" (click)="addNewItem('12.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/13.png" class="js-cd-close" (click)="addNewItem('13.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/14.png" class="js-cd-close" (click)="addNewItem('14.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/15.png" class="js-cd-close" (click)="addNewItem('15.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/16.png" class="js-cd-close" (click)="addNewItem('16.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/17.png" class="js-cd-close" (click)="addNewItem('17.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/18.png" class="js-cd-close" (click)="addNewItem('18.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/19.png" class="js-cd-close" (click)="addNewItem('19.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/20.png" class="js-cd-close" (click)="addNewItem('20.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/21.png" class="js-cd-close" (click)="addNewItem('21.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/22.png" class="js-cd-close" (click)="addNewItem('22.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/23.png" class="js-cd-close" (click)="addNewItem('23.png')"  loading="lazy"/></mat-grid-tile>
    <mat-grid-tile class="mat-grid">   <img src="assets/img/bappa-element/sweets/24.png" class="js-cd-close" (click)="addNewItem('24.png')"  loading="lazy"/></mat-grid-tile>
    
</mat-grid-list>