import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CarouselModule, WavesModule } from 'angular-bootstrap-md'
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule, } from 'ngx-sharebuttons/icons';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { InnerHeaderComponent } from './inner-header/inner-header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaLoaderService,
  RecaptchaModule,
} from 'ng-recaptcha';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BappaContestComponent } from './bappa-contest/bappa-contest.component';
import { ArtGalleryComponent } from './art-gallery/art-gallery.component';
import { ItemView } from './art-gallery/art-gallery.component';
import { SelfieContestComponent } from './selfie-contest/selfie-contest.component';
import { PaintingContestComponent } from './painting-contest/painting-contest.component';
import { PandalRegistrationComponent } from './pandal-registration/pandal-registration.component';
import { PandalContestComponent } from './pandal-contest/pandal-contest.component';
import { LiveCornerComponent } from './live-corner/live-corner.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PaintingContestSingleComponent } from './painting-contest-single/painting-contest-single.component';
import { BappaContestSingleComponent } from './bappa-contest-single/bappa-contest-single.component';
import { SelfieContestSingleComponent } from './selfie-contest-single/selfie-contest-single.component';
import { PandalContestSingleComponent } from './pandal-contest-single/pandal-contest-single.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PinchZoomModule } from 'ngx-pinch-zoom';

const globalSettings: RecaptchaSettings = {
  // siteKey: '6LcG4TsbAAAAAAYCVx0SRW6X3EVsb_N6S9as_Zy2'
  siteKey: '6LegjU4cAAAAAF6z08SRnedjwekvlSAEuF84J5Rm'
};

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegistrationComponent,
    InnerHeaderComponent,
    WelcomeComponent,
    BappaContestComponent,
    ArtGalleryComponent,
    SelfieContestComponent,
    PaintingContestComponent,
    PandalRegistrationComponent,
    PandalContestComponent,
    LiveCornerComponent,
    ForgotPasswordComponent,
    PaintingContestSingleComponent,
    BappaContestSingleComponent,
    SelfieContestSingleComponent,
    PandalContestSingleComponent,
    ItemView
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UserDashboardModule,
    SlickCarouselModule,
    RecaptchaModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
        showForeground:true
    }),
    BrowserAnimationsModule,
    MaterialModule,
    ImageCropperModule,
    FilterPipeModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    CarouselModule, WavesModule,
    NgxUsefulSwiperModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    PinchZoomModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: globalSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
