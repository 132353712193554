import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bappa-garland',
  templateUrl: './bappa-garland.component.html',
  styleUrls: ['./bappa-garland.component.css']
})
export class BappaGarlandComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
  }

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }

}
