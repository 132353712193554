<app-inner-header [title]="title"></app-inner-header>
<main id="main">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
        <div class="container">

            <div class="d-flex justify-content-end align-items-right">
                <!-- <h2>Registration</h2> -->
                <ol>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{title}}</li>
                </ol>
            </div>

        </div>
    </section><!-- End Breadcrumbs Section -->

    <section class="inner-page parallax">
        <div class="container" style="position: relative;">
            <mat-tab-group dynamicHeight>
                <!-- <mat-tab label="Live Performances">
                    <div class="row d-flex justify-content-left">
                        <h4>Youtube Live</h4>
                        <div class="videos"> 
                            <section class="video-section">
                                
                                <article class="video-container" *ngFor="let data of safeSrcPerformances let i = index">

                                    <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                        <iframe class="embed-responsive-item" [src]="data" allowfullscreen></iframe>
                                    </div>
                                      
                                </article>
                            </section>
                        </div>
        
                    </div>

                    <mat-divider></mat-divider>

                    <div class="row d-flex justify-content-left">
                        <h4>Facebook Live</h4>
                        <div class="videos"> 
                            <section class="video-section">
                                
                                <article class="video-container" *ngFor="let data of fbVideosData let i = index">

                                    <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                        
                                        <a href="{{data.fbVideoUrl}}" target="_blank">
                                        <img alt="" class="img-fluid"
                                        src='{{"https://timesbappa-uat-data.s3.ap-south-1.amazonaws.com/fbthumb/"+data.imagePath}}' />
                                        </a>
                                    </div>
                                      
                                </article>
                            </section>
                        </div>
        
                    </div>
                   
                </mat-tab>


                <mat-tab label="Live Workshops">
                    <div class="row d-flex justify-content-left">
                        <div class="videos">
                            <section class="video-section">
                                <article class="video-container" *ngFor="let data of safeSrcWorkshops let i = index">

                                    <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                        <iframe class="embed-responsive-item" [src]="data" allowfullscreen></iframe>
                                    </div>

                                      
                                </article>
        
                            </section>
        
        
                        </div>
        
        
        
                    </div>
                </mat-tab> -->

                <mat-tab label="Live Darshan & Aarti">
                    <div class="row d-flex justify-content-left">
                        <!-- <h4>Youtube Live</h4> -->
                        <div class="videos"> 
                            <section class="video-section">
                                
                                <article class="video-container" *ngFor="let data of safeSrcAarti let i = index">

                                    <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                        <iframe class="embed-responsive-item" [src]="data" allowfullscreen></iframe>
                                    </div>
                                      
                                </article>
                            </section>
                        </div>
        
                    </div>

                    <mat-divider></mat-divider>

                    <!-- <div class="row d-flex justify-content-left">
                        <h4>Facebook Live</h4>
                        <div class="videos"> 
                            <section class="video-section">
                                
                                <article class="video-container" *ngFor="let data of fbVideosData let i = index">

                                    <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                        
                                        <a href="{{data.fbVideoUrl}}" target="_blank">
                                        <img alt="" class="img-fluid"
                                        src='{{"https://s3.ap-south-1.amazonaws.com/www.timesbappa.com-data/fbthumb/"+data.imagePath}}' />
                                        </a>
                                    </div>
                                      
                                </article>
                            </section>
                        </div>
        
                    </div> -->
                </mat-tab>

            </mat-tab-group>


            <div class="row d-flex justify-content-center">

                <!-- <div class="col-lg-6 col-md-6">
                    <div class="member">
                      <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                          style="color:#fff">Upload Selfie</a></button>
                    </div>
                  </div> -->
                <!-- <div class="row mb-3 d-flex justify-content-center text-center"  *ngIf="city != 'Mumbai'">
      
                  <div class="col-lg-4 col-sm-12">
                    <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
                      <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                          style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
                      <div class="col-12 col-lg-12 sponsor-grid-left">
                        <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                          <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy" />
                        </a>
      
                      </div>
                    </div>
      
                  </div>
      
      
      
                  <div class="col-lg-8 col-sm-12">
      
                    <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
                      <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
                      </p>
      
      
                      <div class="row mb-3 d-flex justify-content-center text-center">
                        <div class="col-4 col-lg-3 sponsor-grid mt-3">
                          <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                            <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy"/>
                          </a>
      
                        </div>
      
                        <div class="col-4 col-lg-3 sponsor-grid">
                          <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                            <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                          </a>
      
                        </div>
      
                        <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                          <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                            <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy"/>
                          </a>
      
                        </div>
      
                        <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                          <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                            <img src="assets/img/sponsor-logo/union_bank_logo.png" style="width:100%" loading="lazy" />
                          </a>
      
                        </div>
      
      
      
      
      
                      </div>
      
      
      
      
                    </div>
      
      
                  </div>
                </div> -->
      
      
              </div>
            

        </div>
    </section>

</main><!-- End #main -->

<app-footer></app-footer>