import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';
//import { fabric } from 'fabric';
import { fabric } from 'fabric-with-gestures';
import html2canvas from 'html2canvas';
import { Router, ActivatedRoute } from '@angular/router';
import {ApiService} from '../../api.service';
import {
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



@Component({
  selector: 'app-edit-bappa',
  templateUrl: './edit-bappa.component.html',
  styleUrls: ['./edit-bappa.component.css']
})
export class EditBappaComponent implements OnInit {
  canvas: any;
  @ViewChild('myCanvas') myCanvas: ElementRef;
  @ViewChild('newCanvas') newCanvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  bappaView:boolean = false;
  backgroundselect:boolean=true;
  idolselect:boolean = false;
  garlandselect:boolean=false;
  flowerselect:boolean=false;
  decorselect:boolean=false;
  sweetsselect:boolean=false;
  fruitsselect:boolean=false;

  backgroundUrl:string;
  idolUrl:string;
  garlandUrl:string;
  flowerUrl:string;
  decorUrl:string;
  sweetsUrl:string;
  fruitsUrl:string;

  imgUrl:any = [
    {url:"./assets/img/bappa-element/idol/1.png"},
  ]


  public userId;
  public token;

  userName: string = ''
  userEmail: string = ''
  userPhone: string = ''

  getData = {};
  respData: any = {};
  userData: any = {};

  userImageDataArray: any ={}
  userImageData:any={}


  created:boolean=false;
  canvasData:any;

  imagePath:string="";
  imageData:any={}
  canvasJson:any;
  panelTitle:string;


  slides = [
    {img: "assets/img/bappa-element/thumbnail/background.jpg", name:"background"},
    {img: "assets/img/bappa-element/thumbnail/garland.png", name:"garland"},
    {img: "assets/img/bappa-element/thumbnail/flower.png", name:"flower"},
    {img: "assets/img/bappa-element/thumbnail/decor.png", name:"decor"},
    {img: "assets/img/bappa-element/thumbnail/sweets.png", name:"sweets"},
    {img: "assets/img/bappa-element/thumbnail/fruits.png", name:"fruits"}
  ];

  slideConfig = {
  "slidesToShow": 4, 
  "slidesToScroll": 1,  
  // "dots": true, 
  "infinite": false,
  // "autoplay": true,
  // "autoplaySpeed": 1500
  };
  
  // addSlide() {
  //   this.slides.push({img: "http://placehold.it/350x150/777777"},)
  // }
  
  // removeSlide() {
  //   this.slides.length = this.slides.length - 1;
  // }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }  

  constructor(private router:Router, private api:ApiService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.loadJsSlidePanel("assets/js/slider-panel.js");  
     
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    

    this.canvas = new fabric.Canvas('myCanvas')
    var context = this.canvas.getContext("2d");

    context.fillStyle = "#fff";
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    this.checkPosition()

  //  let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  //   let height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
  //   let altezza = height / 100 * 70;
  //   this.canvas.setDimensions({ width: width, height: altezza});

    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.router.navigateByUrl('/');
    }

    this.getUserData();
    
  }

  
 

  loadJsSlidePanel(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('head')[0].appendChild(node);  
  }

  
  getUserData() {
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;

          this.userName = this.userData.name;
          this.userEmail = this.userData.email;
          this.userPhone = this.userData.phone;
          //this.imagePath = this.userData.imagePath;

         // this.profileImageURL = this.profileImageURL + this.imagePath;

          if (this.userData.createBappa == 'yes') {
            this.getUserImageData()
            
          } else {
            this.created = false;
          }


          console.log('resp data', this.respData.data);
          console.log('user data', this.userData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }

  getUserImageData() {
    var self = this;
    if (this.token != null && this.userId != null) {
      this.getData = { userId: this.userId, token: this.token };

      this.api.getUserImageData(this.getData).subscribe(
        (resp) => {
          this.userImageDataArray = resp;
          this.userImageData = this.userImageDataArray.data;

          var myJson = this.userImageData.canvasJson
          self.canvas.loadFromJSON(myJson, function () {
          //render the canvas
          self.canvas.renderAll();

          var ctx = self.canvas.getContext("2d");
          // ctx.setTransform(1, 0, 0, 1, 0, 0);
        

         

          self.canvas.forEachObject(function(object){ 
            // object.lockScalingX = true;
             object.lockRotation = true;
            object.setControlsVisibility({
              mt: false, 
              mb: false, 
              ml: false, 
              mr: false, 
              // bl: false,
              // br: false, 
              // tl: false, 
              // tr: false,
              // mtr: false, 
            });

               
          });

          var objectx = self.canvas.getObjects();
          // console.log('object', objectx[0])
          // objectx[0].selectable = false;

          for (var index in objectx) {
            console.log(index); // prints indexes: 0, 1, 2, 3
          
            console.log(objectx[index]); // prints elements: 10, 20, 30, 40
            var item = objectx[index].src

            if(item !== undefined){
              console.log('item', item)
              var splitted = item.split("/"); 
              var obj = splitted[6]
            
              console.log('active object is', obj)
              if(obj == "idol"){
                objectx[index].selectable = false;
              }
          
            }
          }

          this.checkPosition()
                
          });



          console.log('resp data', this.userImageDataArray.data);
          console.log('image data', this.userImageData);
        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
    }
  }

  

  
  getObject(data:any){
    if(data == "background"){
      this.backgroundEnable()
      this.panelTitle = "Set Background for Bappa"
    }
    if(data == "garland"){
      this.garlandEnable()
      this.panelTitle = "Get Garland for Bappa"
    }
    if(data == "flower"){
      this.flowerEnable()
      this.panelTitle = "Get Flowers for Bappa"
    }
    if(data == "decor"){
      this.decorEnable()
      this.panelTitle = "Decorate your Bappa"
    }
    if(data == "sweets"){
      this.sweetsEnable()
      this.panelTitle = "Get Sweets for Bappa"
    }
    if(data == "fruits"){
      this.fruitsEnable()
      this.panelTitle = "Get Fruits for Bappa"
    }

  }

  



  backgroundEnable(){
    this.backgroundselect=true;
    this.idolselect = false;
    this.garlandselect = false;
    this.flowerselect = false;
    this.decorselect=false
    this.sweetsselect= false;
    this.fruitsselect= false;  
  }

 

  garlandEnable(){
    this.backgroundselect=false;
    this.idolselect = false;
    this.garlandselect = true;
    this.flowerselect = false;
    this.decorselect=false
    this.sweetsselect= false;
    this.fruitsselect= false;   
   
  }

  flowerEnable(){
    this.backgroundselect=false;
    this.idolselect = false;
    this.garlandselect = false;
    this.flowerselect = true;
    this.decorselect=false
    this.sweetsselect= false;
    this.fruitsselect= false;   
   
  }

  decorEnable(){
    this.backgroundselect=false;
    this.idolselect = false;
    this.garlandselect = false;
    this.flowerselect = false;
    this.decorselect=true
    this.sweetsselect= false;
    this.fruitsselect= false;   
   
  }


  sweetsEnable(){
    this.backgroundselect=false;
    this.idolselect = false;
    this.garlandselect = false;
    this.flowerselect = false;
    this.decorselect=false
    this.sweetsselect= true;
    this.fruitsselect= false;  
   
  }

  fruitsEnable(){
    this.backgroundselect=false;
    this.idolselect = false;
    this.garlandselect = false;
    this.flowerselect = false;
    this.decorselect=false
    this.sweetsselect= false;
    this.fruitsselect= true;  
   
  }

  setBackground(data:string){
    //alert(data)
    this.backgroundUrl = "./assets/img/bappa-element/background/"+data;
    // alert(this.backgroundUrl)
     var self=this;
  
    self.canvas.selection=false;
    // this.canvas.add(new fabric.IText('Hello Bappa!'));
    fabric.Image.fromURL(this.backgroundUrl, (img) => {
      img.set({
        scaleX: this.canvas.width/img.width,
        scaleY: this.canvas.height/img.height,
      });
      this.canvas.setBackgroundImage(img, this.canvas.requestRenderAll.bind(this.canvas));

    });
  }



  
  setGarland(data:any){
    //alert(data)
    this.garlandUrl = "./assets/img/bappa-element/garland/"+data;
    // alert(this.garlandUrl)

    var self=this;
    
    self.canvas.selection=false;

    fabric.Image.fromURL(this.garlandUrl, function(img) {
      img.set({
          top: 15,
          left: 15, 
          scaleX: (self.canvas.width / img.width)/1.1,
          scaleY: (self.canvas.height / img.height)/1.1, 
          lockRotation : true
          
      });

      img.setControlsVisibility({
        mt: false, 
        mb: false, 
        ml: false, 
        mr: false 
      });

        //  self.canvas.on('touch:gesture',function(event){
        //  // isGestureEvent = true;      
        //   var lPinchScale = event.self.scale;  
        //   var scaleDiff = (lPinchScale -1)/10 + 1;  // Slow down zoom speed    
        //   self.canvas.setZoom(self.canvas.viewport.zoom*scaleDiff);
      
        //   });
         self.canvas.selection=true;
         self.canvas.setActiveObject(img)
         self.canvas.add(img);
         self.canvas.renderAll();

        //  self.canvas.on("mouse:wheel", function(opt) {
        //   opt.e.preventDefault()
        //   opt.e.stopPropagation()
        //   if (opt.e.ctrlKey) {
        //     console.log("pinch")
        //     var delta = opt.e.deltaY;
        //     var zoom = self.canvas.getZoom();
        //     zoom *= 0.999 ** delta;
        //     self.canvas.setZoom(zoom);
        //   } else {
        //     var e = opt.e;
        //     var vpt = this.viewportTransform;
        //     vpt[4] += e.deltaX;
        //     vpt[5] += e.deltaY;
        //     this.requestRenderAll();
        //   }
        // })
        
    });



   
  }

  setFlower(data:any){
    //alert(data)
    this.flowerUrl = "./assets/img/bappa-element/flower/"+data;
    // alert(this.flowerUrl)

    var self=this;
    
    self.canvas.selection=false;

    fabric.Image.fromURL(this.flowerUrl, function(img) {
      img.set({
          top: 15,
          left: 15, 
          scaleX: (self.canvas.width / img.width)/1.1,
          scaleY: (self.canvas.height / img.height)/1.1, 
          lockRotation : true
         });
         img.setControlsVisibility({
          mt: false, 
          mb: false, 
          ml: false, 
          mr: false
        });
         
         self.canvas.selection=true;
         self.canvas.setActiveObject(img)
         self.canvas.add(img);
         self.canvas.renderAll();
        
    });

    // self.canvas.on({'touch:gesture': function(opt) {
    //     console.log('pinch working')
    //     opt.e.preventDefault()
    //     opt.e.stopPropagation()
    //     console.log("pinch")
    //     var delta = opt.e.deltaY;
    //     var zoom = self.canvas.getZoom();
    //     zoom *= 0.999 ** delta;
    //     self.canvas.setZoom(zoom);
    //   }
    // });

  }

  setDecor(data:any){
    //alert(data)
    this.decorUrl = "./assets/img/bappa-element/decor/"+data;
    // alert(this.decorUrl)

    var self=this;
    
    self.canvas.selection=false;

    fabric.Image.fromURL(this.decorUrl, function(img) {
      img.set({
          top: 15,
          left: 15, 
          scaleX: (self.canvas.width / img.width)/1.1,
          scaleY: (self.canvas.height / img.height)/1.1,
          lockRotation : true
         });

         img.setControlsVisibility({
          mt: false, 
          mb: false, 
          ml: false, 
          mr: false 
        });
         self.canvas.selection=true;
         self.canvas.setActiveObject(img)
         self.canvas.add(img);
         self.canvas.renderAll();
        
    });
  }
  

  setSweets(data: any){
     //alert(data)
      this.sweetsUrl = "./assets/img/bappa-element/sweets/"+data;
      // alert(this.sweetsUrl)

      var self=this;
      
      self.canvas.selection=false;    
  
      fabric.Image.fromURL(this.sweetsUrl, function(img) {
       
        img.set({
            top: 15,
            left: 15, 
            scaleX: (self.canvas.width / img.width)/1.1,
            scaleY: (self.canvas.height / img.height)/1.1,
            lockRotation : true
          });

          img.setControlsVisibility({
            mt: false, 
            mb: false, 
            ml: false, 
            mr: false
          });
          self.canvas.selection=true;
          self.canvas.setActiveObject(img)
          self.canvas.add(img);
          self.canvas.renderAll();
          
      });
  }

  setFruits(data: any){
    //alert(data)
     this.fruitsUrl = "./assets/img/bappa-element/fruits/"+data;
    //  alert(this.fruitsUrl)

     var self=this;
     
     self.canvas.selection=false;

     fabric.Image.fromURL(this.fruitsUrl, function(img) {
       img.set({
            top: 15,
            left: 15, 
            scaleX: (self.canvas.width / img.width)/1.1,
            scaleY: (self.canvas.height / img.height)/1.1,
            lockRotation : true
         });

         img.setControlsVisibility({
          mt: false, 
          mb: false, 
          ml: false, 
          mr: false
        });
         self.canvas.selection=true;
         self.canvas.setActiveObject(img)
         self.canvas.add(img);
         self.canvas.renderAll();
         
     });
 }


 
      

  saveBappa(){
    var self = this;
    var canvasDataUrl = self.myCanvas.nativeElement.toDataURL().replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
    var link = document.createElement('a'); // create an anchor tag

    // set parameters for downloading
    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', "ganpati.png");

    // compat mode for dispatching click on your anchor
    if (document.createEvent) {
        var evtObj = document.createEvent('MouseEvents');
        evtObj.initEvent('click', true, true);
        link.dispatchEvent(evtObj);
    } else if (link.click) {
        link.click();
    }
    // this.canvas2 = new fabric.Canvas("newCanvas");
    var myJson = JSON.stringify(self.canvas.toJSON(['left', 'top', 'lockMovementX', 'lockMovementY']));
    localStorage.setItem('canvas', myJson)
    // this.canvas2.loadFromJSON(myJson, function () {
    // //render the canvas
    // this.canvascanvas2.renderAll();
    // });
  }

  // save() {
  //   this.canvas.deactivateAll().renderAll();
  //   this.uploadBappa();
  // }


  uploadBappa(){
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
    var data = this.myCanvas.nativeElement.toDataURL();
    console.log('canvas data: ', data); 

   // var img = this.myCanvas.nativeElement.toDataURL();
    var file = this.DataURIToBlob(data);
    
    console.log('file', file);
    this.uploadFile(file);
  }


  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  uploadFile(file: any) {
    console.log('image -- ', file);
    const formData = new FormData();
    formData.append('mobileNumber', this.userPhone);
    formData.append('file', file, 'png');
    file.inProgress = true;
    console.log('form data', formData);
    this.api.imageUpload(formData).pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
         
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          console.log(event.body.data);
          this.imagePath = event.body.imagePath;

          console.log(this.imagePath);
          //alert('Image successfully uploaded');

          this.saveImageData();
        }
      });
  }

  saveImageData() {

    this.canvasJson = JSON.stringify(this.canvas.toJSON(['left', 'top', 'lockMovementX', 'lockMovementY']));
    if (this.imagePath !== null) {
      this.imageData['userId'] = this.userId;
      this.imageData['userName'] = this.userName;
      this.imageData['token'] = this.token;
      this.imageData['imagePath'] = this.imagePath;
      this.imageData['canvasJson'] = this.canvasJson;
      
      this.api.saveimageData(this.imageData).subscribe(
        (resp) => {
          this.respData = resp;
          if (this.respData.success == true) {
            alert('Bappa updation has been done successfully!');
            this.router.navigateByUrl('/user-dashboard');
           
          } else {
          
            alert(this.respData.message);

          
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
         // this.startLoader = false;
          console.log(err);
         
        }
      );
    } else {
      //this.startLoader = false;
      alert('Please create bappa');
     
    }
  }

  remove(){
    var object = this.canvas.getActiveObject();

    if (!object){
      alert('Please select the element to remove');
      return '';
    }
    this.canvas.remove(object);
    // var item = object.src

    // if(item !== undefined){
    //   console.log('item', item)
    //   var splitted = item.split("/"); 
    //   var obj = splitted[6]
    
    //   console.log('active object is', obj)
  
    //   if (!object){
    //       alert('Please select the element to remove');
    //       return '';
    //   }
  
    //   if(obj == "idol"){
    //     alert('You can not change Bappa Idol')
    //   }else{
    //     this.canvas.remove(object);
    //   }
    // }else{
    //   if (!object){
    //     alert('Please select the element to remove');
    //     return '';
    //   }
    //   this.canvas.remove(object);
    // }
    
    
  };

  bringForward(){
    var object = this.canvas.getActiveObject();
    if (!object){
        alert('Please select the element to remove');
        return '';
    }
    this.canvas.bringForward(object);
    this.canvas.setActiveObject(false);
    
  };

  sendBackwards(){
    var object = this.canvas.getActiveObject();
    if (!object){
        alert('Please select the element to remove');
        return '';
    }
    this.canvas.sendBackwards(object);
    this.canvas.setActiveObject(false);
    
  };

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('bappaCreated');
    
    this.refresh();
  }

  refresh(): void {
    window.location.reload();
  }

  checkPosition(){
    this.canvas.on('object:moving', function (e) {
      var obj = e.target;

      obj.setCoords();
      var curZoom = obj.canvas.getZoom();

      //
      // if object is too big ignore
      if(obj.getScaledHeight() > obj.canvas.height || obj.getScaledWidth() > obj.canvas.width){
          return;
      }

      // top-left  corner
      if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
          obj.top = Math.max(obj.top*curZoom, obj.top*curZoom-obj.getBoundingRect().top)/curZoom;
          obj.left = Math.max(obj.left*curZoom, obj.left*curZoom-obj.getBoundingRect().left)/curZoom;
      }
      // bot-right corner
      if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
          obj.top = Math.min(obj.top*curZoom, obj.canvas.height-obj.getBoundingRect().height+obj.top*curZoom-obj.getBoundingRect().top)/curZoom;
          obj.left = Math.min(obj.left*curZoom, obj.canvas.width-obj.getBoundingRect().width+obj.left*curZoom-obj.getBoundingRect().left)/curZoom;
      }
  });

     
  }
  

}
