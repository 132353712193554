 <!-- ======= Footer ======= -->
 <footer id="footer">
    <div class="container">
      <div class="copyright">
        © Copyright <strong><span>BCCL</span></strong>. All Rights Reserved | <span (click)="termsView()" style="cursor:pointer">Terms of Use</span> | <span (click)="privacyView()" style="cursor:pointer">Privacy Policy</span>
      </div>

      <div class="credits">
        Powered by BCCL Rapido <br>
        <!-- Launched: Jul 25, 2020 18:42 IST  -->
      </div>
    </div>
  </footer><!-- End Footer -->
  <a class="back-to-top d-flex align-items-center justify-content-center" href="#"><i
      class="bi bi-arrow-up-short"></i></a>

      