
    <div mat-dialog-content class="card shadow-sm bg-white rounded" style="width:100%; height:100%;">
        
        
            <button type="button" (click)="close()" class="close dialog-close-btn" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        
          <div class="card-header card-header-danger" style="background: #6b6867; color: #fff">
              <h4 class="card-title">{{name}}</h4>
              <p class="card-text"><strong>{{artist}}</strong></p>
          </div>
           <div class="card-body">
            <div class="row justify-content-end mb-2">
                       
                <a href='{{url}}' target="_blank" class="btn-dashboard" style="text-decoration: none; max-width:148px;">
                    <i class="fa fa-shopping-cart"></i> Proceed to Buy
                </a>
                
            
        </div> 
              <div class="card-img-top pb-3" style="overflow: hidden; ">
                <pinch-zoom [properties]="zoomProperties">
                    <img src='{{imgPath}}' style="width:100%" loading="lazy">
                </pinch-zoom>
                  
               </div>

               <div class="row d-fa justify-content-center">
                <a href='{{url}}' target="_blank" class="btn-dashboard" style="text-decoration: none; max-width:148px;">
                    <i class="fa fa-shopping-cart"></i> Proceed to Buy
                </a>
               </div>
               
            
             <!-- <p class="card-text py-5">{{whyHero}}</p> -->
           </div>
 
    </div>


