import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
// import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-painting-contest-single',
  templateUrl: './painting-contest-single.component.html',
  styleUrls: ['./painting-contest-single.component.scss']
})
export class PaintingContestSingleComponent implements OnInit {
  title: string = "Painting & Drawing Contests user wise";
  respData: any = {};
  userData: any = {};
  newUserData:any = {}
  hidden = false;

  userName: string;

  loggedIn:boolean = false;
  public userId;
  public token;

  displayName: string = "";
  city:string="";
  voterData: any = {};


  // pageIndex:number = 0;
  // pageSize:number = 4;
  // lowValue:number = 0;
  // highValue:number = 4;    

  // items = [];
  // pageOfItems: Array<any>;

  dataFilter: any = { "userId": '', "paintingsTitle": ''};


  notEmptyData = true;
  notscrolly = true;
  nextToken:any ="";
  
  getData:any = {}

  uId:any;
  uName:any;


  constructor(private api:ApiService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.city = localStorage.getItem('city');
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null && this.userId == null) {
      this.loggedIn = false;
    }else{
      this.loggedIn = true;
    }

    this.uId = this.activatedRoute.snapshot.params.id;
    console.log(this.uId);

    //if(this.uId != null) {
      this.getSinglePaintingsData()
    //}
    
    
  }


  getSinglePaintingsData() {
    // if (this.token != null && this.userId != null) {
      this.getData = { uId:this.uId };

      this.api.getSinglePaintingsData(this.getData).subscribe(
        (resp) => {
          this.respData = resp;
          this.userData = this.respData.data;
          this.uName = this.userData[0].userName

          console.log(this.userData)

        },
        (err) => {
          // this.startLoaderOTP = false;
          console.log(err);
        }
      );
  }


  
  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  voteNow(id:any, name:string, img:any){
    console.log(this.loggedIn)
    if(this.loggedIn === true){
      this.voterData['voterId'] = this.userId;
      this.voterData['token'] = this.token;
      this.voterData['userId'] = id;
      this.voterData['userName'] = name;
      this.voterData['imagePath'] = img;

      this.api.voteForPaintings(this.voterData).subscribe(
        (resp) => {
          //alert('working');
          this.respData = resp;
          if (this.respData.success == true) {
            alert(this.respData.message);
           this.refresh();
          } else {
           // this.startLoader = false;
            alert(this.respData.message);
            console.log('error' + this.respData.message);
          }
        },
        (err) => {
          //alert('error');
         // this.startLoader = false;
          console.log(err);
        }
      );
      
    }else{
      alert("plaese login for voting");
      this.router.navigate(['/login', { landingPage:"/painting-contest", id: id }]);
    } 
  }

  refresh(): void {
    window.location.reload();
  }
}
