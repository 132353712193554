<app-inner-header [title]="title"></app-inner-header>
<main id="main">

  <!-- ======= Breadcrumbs Section ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-end align-items-right">
        <!-- <h2>Registration</h2> -->
        <ol>
          <li><a routerLink="/">Home</a></li>
          <li>{{title}}</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs Section -->

  <section class="inner-page parallax">
    <div class="container" style="position: relative;">
      <div class="row d-flex justify-content-left mb-3">
        <div class="form-group col-8 col-lg-4">
          <input mat-input type="text" #searchTerm placeholder="Search by User Name" class="form-control" type="text" [(ngModel)]="inputvalue" 
          (ngModelChange)="changeToSentenceCase($event)">
        </div>
        
        <div class="form-group col-4 col-lg-3">
          <button mat-button>
            <a class="btn-dashboard" style="text-decoration: none !important;" (click)="searchUserBappa(searchTerm.value)" *ngIf="!searchable">
              Search
            </a>
            <a class="btn-dashboard" style="text-decoration: none !important;" (click)="refresh()" *ngIf="searchable">
              Clear Search
            </a>
          </button>
          
        </div>

      </div>

      <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <div class="row d-flex justify-content-left stories-container show-social" (click)="closeShareOptions()">
          <div class="col-lg-3 col-md-3" *ngFor="let user of userData; let i = index">
            <mat-card class="contest-wal-card">
              <!-- <mat-card-header>
                      <mat-card-title>Bappa : {{user.imagePath}}</mat-card-title>
                      <mat-card-subtitle>{{user.userName}}</mat-card-subtitle>
                    </mat-card-header> -->
              <img mat-card-image
                src='{{environment.image_url +"bappaidol/" + user.imagePath}}' loading="lazy" alt="">
              <mat-card-content>
                <p style="height: auto">
                  Created By: {{user.userName | titlecase}}
                  
                </p>
              </mat-card-content>
              <mat-card-actions align="justify">

                <share-buttons [theme]="'circles-dark'" [include]="['facebook','twitter','linkedin','whatsapp']"
                  [show]="4" [size]="1"
                  [url]="'https://timesbappa.com/bappa-contest/'+user.userId+'/'+user.imagePath+'/'"
                  [image] = "environment.image_url + 'bappaidol/' + user.imagePath"
                  [autoSetMeta]="false"
                  *ngIf="user.shareOnWeb" ></share-buttons>
                  <!-- [image]="'https://s3.ap-south-1.amazonaws.com/www.timesbappa.com-data/bappaidol/' + user.imagePath" -->
                <div class="action-area align-middle social-container">
                  <button mat-button>Votes: {{user.voteCount}}</button>
                  <button mat-button><a class="btn-dashboard" style="text-decoration: none !important;"
                      (click)="voteNow(user.id, user.userName, user.imagePath)">VOTE</a></button>
                  <span class="custom-icon share-icon-white"  (click)="share($event, user)"></span>
                </div>

                <!-- <a href="https://web.whatsapp.com/send?text=http://timesbappa-uat.s3-website.ap-south-1.amazonaws.com/bappa-contest/{{user.userId}}/{{user.imagePath}}" data-action="share/whatsapp/share" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s" target="_blank">Share1</a>
                <a href="whatsapp://send?text=http://timesbappa-uat.s3-website.ap-south-1.amazonaws.com/bappa-contest/{{user.userId}}/{{user.imagePath}}" data-text="Vote for my Bappa:" data-action="share/whatsapp/share" class="wa_btn wa_btn_s">Share1</a> -->


              </mat-card-actions>
            </mat-card>
          </div>



        </div>

      </div>



      <div *ngIf="!notEmptyData" class="row pb-5">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="text-muted h6 alert alert-secondary p-2">No more items to show</div>
        </div>
      </div>

      <div class="row d-flex justify-content-center">

        <!-- <div class="col-lg-6 col-md-6">
            <div class="member">
              <button class="btn btn-secondary"><a href="#upload" class="nav-link"
                  style="color:#fff">Upload Selfie</a></button>
            </div>
          </div> -->
        <div class="row mb-3 d-flex justify-content-center text-center" *ngIf="city !='Mumbai' " >
          <!-- <div class="col-lg-4 col-sm-12">
            <div class="row mb-3 d-flex justify-content-center text-center sponsor-left">
              <p class="animate__animated animate__fadeInDown display-7" style="color:#fe6717; margin-top:10px;"><span
                  style="color: rgba(26, 24, 22, 0.85);">Powered</span> By</p>
              <div class="col-12 col-lg-12 sponsor-grid-left">
                <a href="https://www.midcindia.org/home" target="_blank" alt="MIDC">
                  <img src="assets/img/sponsor-logo/midc_logo.png" loading="lazy"/>
                </a>

              </div>
            </div>

          </div> -->



          <!-- <div class="col-lg-8 col-sm-12">

            <div class="row mb-3 d-flex justify-content-center text-center sponsor-right">
              <p class="display-7 association"><span style="color: rgba(26, 24, 22, 0.85);">In association</span> with
              </p>


              <div class="row mb-3 d-flex justify-content-center text-center">
                <div class="col-4 col-lg-3 sponsor-grid mt-3">
                  <a href="https://www.asus.com/in/" target="_blank" alt="Asus">
                    <img src="assets/img/sponsor-logo/asus_logo.png" loading="lazy" />
                  </a>

                </div>

                <div class="col-4 col-lg-3 sponsor-grid">
                  <a href="https://www.orra.co.in/" target="_blank" alt="Orra">
                    <img src="assets/img/sponsor-logo/orra_logo.png" loading="lazy"/>
                  </a>

                </div>

                <div id="ubi_web" class="col-4 col-lg-3 sponsor-grid mt-3">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png" loading="lazy" style="width:100%" />
                  </a>

                </div>

                <div id="ubi_mob" class="col-4 col-lg-3" style="width:220px !important">
                  <a href="https://www.unionbankofindia.co.in/" target="_blank" alt="Union Bank">
                    <img src="assets/img/sponsor-logo/union_bank_logo.png"  loading="lazy" style="width:100%" />
                  </a>

                </div>





              </div>




            </div>


          </div> -->




        </div>


      </div>

    </div>
  </section>

</main><!-- End #main -->

<app-footer></app-footer>